import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom"
import logo from '../../assets/img/logo.png'
import background from '../../assets/img/background-login.png'
import { Formik, Form, Field, ErrorMessage } from 'formik';

 function Login () {
  const navigate = useNavigate();


  const [form] = useState({
    username: "",
    password: "",
  });

  const handleSubmit = async (formData) => {
    const error = document.getElementById("error");
    try {
      const { data } = await axios.post(process.env.REACT_APP_BACK_URL + '/admin/login', formData);
      var dataResp = data;
      if(data.error){
        error.innerHTML = "<label>Acceso Incorrecto</label>";
        return
      }
      
      if(data?.roles?.includes('superadmin')){
        const {data} = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + 5 + '&skip=' + 0);
        const response = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + 300 + '&skip=' + 0);
        const { datatotal } = response.data;

        const responseActives = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all-actives' + '?limit=' + 300 + '&skip=' + 0);
        const actives  = responseActives.data;
       // const {datatotal} = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + 500 + '&skip=' + 0);
        localStorage.setItem("companies-count", JSON.stringify(data.count));
        localStorage.setItem("companies", JSON.stringify(data.companies));
        localStorage.setItem("actives-companies", JSON.stringify(actives.companies));
       
        localStorage.setItem("companiestotal", JSON.stringify(response.data.companies));
        localStorage.setItem("role", "superadmin");
      }else{
        const usersQuantityResponse = await axios.get(process.env.REACT_APP_BACK_URL + '/auth/count/' + data?.companyId);
        const adminsQuantityResponse = await axios.get(process.env.REACT_APP_BACK_URL + '/admin/count/' + data?.companyId);
        localStorage.setItem("company", JSON.stringify(dataResp.company) || '{}');
        localStorage.setItem("users-quantity", usersQuantityResponse?.data.usersQuantity);
        localStorage.setItem("admins-quantity", adminsQuantityResponse?.data.adminsQuantity);
        localStorage.setItem("role", "admin");
      }
      
      
      localStorage.setItem("isLoggedIn", true);
      navigate('/');


    } catch (err) {
      console.log(err.response.data)
    }
  };

  return (
    <div className='row'>
      <div className='background-container col-lg-8 d-none d-lg-block'>
        <img src={background} className='background' alt='background'></img>
      </div>
      <div className='col-lg-4 d-flex align-items-center'>
        <div className="container p-4">
          <div className="row justify-content-center">
            <div className="col-6 justify-content-center d-grid">
              <div className='d-flex justify-content-center'>
                <img src={logo} alt='logo' style={{ maxWidth: '350px', padding: '20px'}} />
              </div>
              <Formik
                initialValues={{ username: form.username, password: form.password }}
                validate={(values) => {
                  const errors = {};
                  if (!values.username) {
                    errors.username = 'Campo usuario es requerido';
                  } 

                  if (!values.password) {
                    errors.password = 'Campo contraseña es requerido';
                  } 
                  return errors;
                }}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="mb-3 form-group">
                    <label htmlFor="username">Usuario <sup>*</sup> </label>
                    <Field type="text" name="username" id="username" className='form-control' placeholder="Ingresa tu usuario" maxLength={60}  />
                    <ErrorMessage name="username" component="div" />
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="pass">Contraseña <sup>*</sup> </label>
                    <Field type="password" name="password" id="password" className='form-control' placeholder="Ingresa tu contraseña" maxLength={15} />
                    <ErrorMessage name="password" component="div" />
                  </div>
                  <div id="error" className="error-message"></div>
                  <div className="d-grid">
                    <button type="submit" className='btn-primary-custom btn'>
                      Ingresar
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
 export default Login;