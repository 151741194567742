import { useEffect, useState } from "react";
import { Field, Formik, Form } from "formik";
import Sidebar from "../sidebar/Sidebar";
import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Options from '../../options'
import GaugeChart from 'react-gauge-chart'


function Dashboard  () {

    const options = Options.charts;
    const dates = Options.dates;
    const radios = Options.radios;
    const colors = Options.gauge.colors;
    const role = localStorage.getItem("role");
    const companiesActives = JSON.parse(localStorage.getItem("actives-companies") || JSON.stringify([{}]));
    const [companies, setCompanies] = useState([]);
    const [countries, setCountries] = useState();
    const [type, setType] = useState('date');
    const [showDates, setShowDates] = useState(true);
    const [showCountriesCities, setShowCountriesCities] = useState(false);
    const [cities, setCities] = useState();
    const company = JSON.parse(localStorage.getItem("company") || JSON.stringify({}));
    const [idCompanySelected, setIdCompanySelected] = useState(companiesActives[0]?._id || company?._id);
    ChartJS.register(ArcElement, Tooltip, Legend);

    const chartInitialState = {
        labels: options?.emotions?.labels,
        datasets: [],
    }

    const [emotionsChartData, setEmotionsChartData] = useState(chartInitialState)
    const [totalCount, setTotalCount] = useState();
    const [emotionsCount, setEmotionsCount] = useState();
    const [emotionsLabels, setEmotionsLabels] = useState();
    const [noInfo, setNoInfo] = useState(false);

    useEffect(() => {
        if(role === 'admin'){
            axios.get(process.env.REACT_APP_BACK_URL + '/vote/count-votes-by-state?companyId=' + company?._id + '&dates=total')
            .then((res) => {
                const data = res.data;
                const states = [];
                data?.states.map(state => {
                    states.push(state.charAt(0).toUpperCase() + state.slice(1))
                })

                const chartDataToSave = {
                    labels: states,
                    datasets: [
                        {
                            label: options?.emotions?.generalLabel,
                            data: data?.counts,
                            backgroundColor: options?.emotions?.backgroundColor,
                            hoverOffset: 4
                        },
                    ],
                };
                setEmotionsCount(data?.counts)
                setEmotionsChartData(chartDataToSave)
                setTotalCount(data?.totalCount)
                setEmotionsLabels(states)

            })
            .catch((err) => {
                console.log(err);
            });
        }
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + 300 + '&skip=' + 0);
            localStorage.setItem("companiestotal", JSON.stringify(response.data.companies));
            setCompanies(response.data.companies);

            if(role === 'admin'){
                const {data} = await axios.get(process.env.REACT_APP_BACK_URL + '/auth/companies-countries?companyId=' + company?._id );
            
                const upperCaseCountriesData = [];
                data.forEach(element => {
                    upperCaseCountriesData.push(element?._id?.toUpperCase())
                });
                setCountries(upperCaseCountriesData);

                const citiesResponse = await axios.get(process.env.REACT_APP_BACK_URL + '/auth/companies-cities?companyId=' + company?._id );
                    
                const upperCaseCitiesData = [];
                citiesResponse.data.forEach(element => {
                    upperCaseCitiesData.push(element?._id?.toUpperCase())
                });
                setCities(upperCaseCitiesData);
            }
            else{
                const {data} = await axios.get(process.env.REACT_APP_BACK_URL + '/auth/companies-countries?companyId=' + response?.data?.companies[0]?._id );
            
                const upperCaseCountriesData = [];
                data.forEach(element => {
                    upperCaseCountriesData.push(element?._id?.toUpperCase())
                });
                setCountries(upperCaseCountriesData);


                const citiesResponse = await axios.get(process.env.REACT_APP_BACK_URL + '/auth/companies-cities?companyId=' + response?.data?.companies[0]?._id );
                    
                const upperCaseCitiesData = [];
                citiesResponse.data.forEach(element => {
                    upperCaseCitiesData.push(element?._id?.toUpperCase())
                });
                setCities(upperCaseCitiesData);
            }

        };
        fetchData();
      }, [])

      useEffect(() => {
        const fetchCountries = async () => {
            const {data} = await axios.get(process.env.REACT_APP_BACK_URL + '/auth/companies-countries?companyId=' + idCompanySelected );
            
                const upperCaseCountriesData = [];
                data.forEach(element => {
                    upperCaseCountriesData.push(element?._id?.toUpperCase())
                });
                setCountries(upperCaseCountriesData);

        };
        fetchCountries();

        const fetchCities = async () => {
            const {data} = await axios.get(process.env.REACT_APP_BACK_URL + '/auth/companies-cities?companyId=' + idCompanySelected );
                    
                const upperCasedata = [];
                data.forEach(element => {
                    upperCasedata.push(element?._id?.toUpperCase())
                });
                setCities(upperCasedata);
        };
        fetchCities();

    }, [idCompanySelected])


    const handleSubmit = async (formData) => {

        const {date, city, country} = formData


        if(type === 'date'){
            axios.get(process.env.REACT_APP_BACK_URL + '/vote/count-votes-by-state?companyId=' + idCompanySelected + '&dates=' + date )
            .then((res) => {
                if(res?.data?.totalCount === 0){
                    setNoInfo(true);
                }else{
                    const data = res.data;
                    const states = [];
                    data?.states.map(state => {
                        states.push(state.charAt(0).toUpperCase() + state.slice(1))
                    })
    
                    const chartDataToSave = {
                        labels: states,
                        datasets: [
                            {
                                label: options?.emotions?.generalLabel,
                                data: data?.counts,
                                backgroundColor: options?.emotions?.backgroundColor,
                                hoverOffset: 4
                            },
                        ],
                    };
                    setEmotionsCount(data?.counts)
                    setEmotionsChartData(chartDataToSave)
                    setTotalCount(data?.totalCount)
                    setEmotionsLabels(states)
                    setNoInfo(false);
                }
    
            })
            .catch((err) => {
                console.log(err);
            });
    
        }else{
            axios.get(process.env.REACT_APP_BACK_URL + '/vote/count-votes-by-city-country?companyId=' + idCompanySelected + '&country=' + country + '&city=' + city )
            .then((res) => {
                if(res?.data?.userCounts === 0){
                    setNoInfo(true);
                }else{
                    const data = res.data;
                    console.log(data);
                    const states = [];
                    data?.states.map(state => {
                        states.push(state.charAt(0).toUpperCase() + state.slice(1))
                    })
    
                    const chartDataToSave = {
                        labels: states,
                        datasets: [
                            {
                                label: options?.emotions?.generalLabel,
                                data: data?.counts,
                                backgroundColor: options?.emotions?.backgroundColor,
                                hoverOffset: 4
                            },
                        ],
                    };
                    setEmotionsCount(data?.counts)
                    setEmotionsChartData(chartDataToSave)
                    setTotalCount(data?.totalCount)
                    setEmotionsLabels(states)
                    setNoInfo(false);
                }
    
            })
            .catch((err) => {
                console.log(err);
            });
        }



       
    }

    const selectCompany = (event) => {
        setIdCompanySelected(event?.target?.value)
    }

    const setChecked = (event) => {
        switch (event?.target?.value) {
            case "date":
                setShowDates(true);
                setShowCountriesCities(false);
                setType('date')
                break;
            case "country-city":
                setShowCountriesCities(true);
                setShowDates(false);
                setType('country-city')
                break;
        
            default:
                setShowDates(true);
                setShowCountriesCities(false);
                setType('date')
                break;
        }
    }

    const [form] = useState({
        companyId: company?.id || companies[0]?.id, dates: "total", country: company?.country || companies[0]?.country, city: company?.city || companies[0]?.city
    });


    return (
        <div className="background-colored">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-2 justify-content-center sidebar">
                    <Sidebar />
                </div>
                <div className="col-12 col-sm-9 justify-content-center datatable-container ml">
                    <div className='header-container mt-3 mb-3'>
                        <span className='header-label'>¿Cómo se sienten tus colaboradores?</span>
                    </div>
                    <div className="p-2">
                        <Formik
                            initialValues={{ companyId: form.companyId, date: form.dates, country: form.country, city: form.city, radioItem: form.radioItem }}
                            onSubmit={handleSubmit}
                        >
                            <Form>
                                <div className="row mb-2">
                                        {radios.map((item, key) => (
                                            <div className="col-sm-2" key={key}>
                                                <label htmlFor={item.value} className="form-check-label form-check " >
                                                    <input type="radio" id={item.value} name="radioItem" value={item.value} className="form-check-input" defaultChecked={key === 0} onChange={setChecked} /> 
                                                    {item.label}
                                                </label>
                                            </div>
                                        ))}

                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-3">
                                        <label htmlFor="country">Empresa<sup>*</sup> </label>
                                        <Field as="select" id="companyId" name="companyId" value={idCompanySelected} className='form-select fs-empresa'  onChange={selectCompany}>
                                        
                                        {role === 'superadmin' ? (
                                            companies.map((company, key) => (
                                                <option key={key} value={company._id} >{company.name}</option>
                                            ))
                                        ) : (
                                            <option value={company._id}>{company.name}</option>
                                        )}
                                        </Field>
                                    </div>
                                    <div className={`col-sm-3 ${showDates ? 'show' : 'hidden'}`}>
                                        <label htmlFor="country">Rango de búsqueda <sup>*</sup> </label>
                                        <Field as="select" id="date" name="date" className='form-select fs-empresa'>
                                            {dates.map((date, key) => (
                                                <option value={date.value} key={key} >{date.label}</option>
                                            ))}
                                        </Field>
                                    </div>
                                    <div className={`col-sm-3 ${showCountriesCities ? 'show' : 'hidden'}`}>
                                        <label htmlFor="country">Seleccione el país <sup>*</sup> </label>
                                        <Field as="select" id="country" name="country" className='form-select fs-empresa'>
                                            <option value='' >País</option>
                                            {countries && countries.map((country, key) => (
                                                <option value={country} key={key}  >{country}</option>
                                            ))}
                                        </Field>
                                    </div>
                                    <div className={`col-sm-3 ${showCountriesCities ? 'show' : 'hidden'}`}>
                                    <label htmlFor="country">Ciudad <sup>*</sup> </label>
                                        <Field as="select" id="city" name="city" className='form-select fs-empresa'>
                                            <option value='' >Seleccione la ciudad</option>
                                            {cities && cities.map((city, key) => (
                                                <option value={city} key={key} >{city}</option>
                                            ))}
                                        </Field>
                                    </div>
                                    <div className="col-sm-2 align-self-end">
                                        <button type="submit" className='btn btn-primary-custom m-0 pl-4 pr-4 pt-2 pb-2' >Filtrar</button>
                                    </div>
                                </div>

                                {noInfo && (
                                    <div className="container mt-4">
                                        <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-light" role="alert">
                                            No se han encontrado registros
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                )}
                                {totalCount > 0 && !noInfo && (
                                    <div>
                                        <div className='mt-4 row text-center'>
                                            <div className='col-12 pt-4 d-flex justify-content-center'>
                                                <Doughnut data={emotionsChartData} className="doughnut"/>
                                            </div>
                                        </div>
                                        <div className='mt-4 row text-center'>
                                            {emotionsCount && emotionsCount?.map((emotionCount, key) => (
                                                <div className="col-xl-3 col-lg-6 pt-4" key={key}>
                                                    <GaugeChart
                                                        nrOfLevels={3}
                                                        percent={emotionCount/100}
                                                        formatTextValue={str => str.toString().replace('%', '')}
                                                        needleColor={'#AAA'}
                                                        arcWidth={0.3}
                                                        title={'hola'}
                                                        cornerRadius={0}
                                                        colors={[colors[key], colors[key], colors[key]]}
                                                    />
                                                    <span>{emotionsLabels[key]}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;