import React from 'react'
import { Navigate } from 'react-router-dom'


function Protected({ children }) {
  const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn")) || false;
  if (!isLoggedIn) {
    return <Navigate to="login" replace />
  }
  return children
}
export default Protected