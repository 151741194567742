import React, { useContext, useEffect, useState } from 'react';
import Sidebar from '../sidebar/Sidebar'
import Modal from 'react-modal';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { StoreContext } from '../../context/Store';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UploadS3 from '../../services/s3-upload';
import DeviceChecker from '../../services/is-mobile--checker';
import IsMobile from '../../services/is-mobile--checker';

const MySwal = withReactContent(Swal)

const customStyles = {
  content: {
    top: '25%',
    left: '25%',
    right: '25%',
    bottom: '25%',
  },
};

Modal.setAppElement('#root');


function Clients() {
  const [loaded, setLoaded] = useState(false);
  const [paginatorNumber, setPaginatorNumber] = useState(15);

  useEffect(() => {
    const isMobile = IsMobile();
    if(isMobile){
      setPaginatorNumber(5);
    }
    setLoaded(true);
  }, []);

  const [isLoadingConta, setIsLoadingConta] = useState(true);
 
  const companies = JSON.parse(localStorage.getItem("companies") || JSON.stringify([{}]));
  const companiestotal = JSON.parse(localStorage.getItem("companiestotal") || JSON.stringify([{}]));
  const [conteoSuperAdminR, setConteoSuperAdminR] = useState();
  var conteoSuperAdmin = [];
  const [skip, setSkip] = useState(0);
  const [limit] = useState(5);

  useEffect(() => {
    const fetchConteoSuperAdmin = async () => {
      const conteoSuperAdmin = {};
      for (const company of companiestotal) {
        const companyId = company._id;
        const response = await axios.get(process.env.REACT_APP_BACK_URL + '/auth/getAllActiveUsers?companyId=' + companyId);
        conteoSuperAdmin[companyId] = response.data.count;
      }
      setConteoSuperAdminR(conteoSuperAdmin);
       setIsLoadingConta(false);
    };
  
    fetchConteoSuperAdmin();
  }, []);
 
  const companiesCount = JSON.parse(localStorage.getItem("companies-count") || JSON.stringify([{}]));
 //Data const company = JSON.parse(localStorage.getItem("company") || JSON.stringify({}));
 const role = localStorage.getItem("role");
  //const company = role ="admin" ? JSON.parse(localStorage.getItem("company") || "{}"):"{}";
  //const
  const company = JSON.parse(localStorage.getItem("company") || "{}");
  // var company;//=role = ?  : {}
  // if (role == "admin") {
  // }
  // else if (role == "superadmin") {
  //   company = {};
  // }
  // else {
  //   company = {};
  // }
  const usersQuantity = localStorage.getItem("users-quantity");
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState(1);
  const [InputStatus, setInputStatus] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalUpdateIsOpen, setUpdateIsOpen] = useState(false);
  const [dataToUpdate, setdataToUpdate] = useState();
  const [pageNumbers, setPageNumbers] = useState([]);
  const [propertiestoedit, setpropertiestoedit] = useState();
  //setpropertiestoeditvar propertiestoedit;
  const [noInfo, setNoInfo] = useState(false);
  
  const [logoIfAdmin, setLogoIfAdmin] = useState("");
  const [inputLogo, setInputLogo] =  React.useState();//=useState();
  const [stateinputLogo, setSteteinputLogo ] = useState(false);
  const [stateinputBackground, setSteteinputBackground ] = useState(false);
  const [companiesFetched, setcompaniesFetched] = useState();
  const [loading, setLoading] = useState(false);
  const [inputBackground, setInputBackground] = React.useState();
  const [form] = useState({
    company: "", contact: "", state: 'active', logo: "", background: ""
  });
  // useEffect(() => {

  //   if(!companies[0].name && !company.name) 
  //     setNoInfo(true)
    
  // }, [])
  const handlePageClick = (pageNumber) => {
    setSelectedPage(pageNumber);
    setSkip(pageNumber - 1);
  };
  useEffect(() => {
    setLogoIfAdmin(company?.properties?.logo);
    const fetchData = async () => {
      const { data } = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + limit + '&skip=' + skip);
      localStorage.setItem("companies", JSON.stringify(data.companies));

      const newCompaniesReloadInit = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + limit + '&skip=' + skip);
      setcompaniesFetched(newCompaniesReloadInit.data.companies);
    }

    fetchData();
    const arrayOfPages = [];
    for (let i = 1; i <= Math.ceil(companiesCount / limit); i++) {
      arrayOfPages.push(i);
    }
    setPageNumbers(arrayOfPages);

  }, [skip])

  const LoadingModal = () => {
    return (
      <Modal isOpen={true} style={customStyles}>
      <div className='row'>
      <div className='col-9'>Loading...</div>
      <div className='col-3'> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>
  
      </div>
     
  
      </Modal>
    );
  };

  const handleSubmit = async (formData) => {
    setLoading(true); //, background
    const {company, state, contact} = formData;
   /*  const logoUploaded = await UploadS3(inputLogo);
    const backgroundUploaded = await UploadS3(inputBackground); */
    let logoUploaded,backgroundUploaded;
    if (inputLogo && stateinputLogo){
      logoUploaded = await UploadS3(inputLogo);
   } else if (inputLogo) {
     logoUploaded = inputLogo;
   } else {
     logoUploaded = "";
   }
   //
   if (inputBackground && stateinputBackground){
    
      backgroundUploaded = await UploadS3(inputBackground);
   } else if (inputBackground) {
     
 
     backgroundUploaded  = inputBackground;
   } else {
     backgroundUploaded = "";
   }
   


    const msg = {
      happy: "¡Qué bueno que te sientas así!¡Gracias por compartirlo!",
      sad: "Lamentamos tu tristeza. Estamos aquí para apoyarte. Juntos encontraremos una solución. ¡Gracias por compartirlo!",
      surprised: "Entendemos tu sorpresa. Nos ponemos a trabajar en ello para mejorar tu experiencia. ¡Gracias por compartirlo!",
      angry: "Entendemos tu enojo. Cuenta con nosotros para encontrar una buena solución. ¡Gracias por compartirlo!"
    }
    const dataToSend = {
      name: company, 
      isActive: state === "active" ? true : false, 
      contact: contact,
      properties: {
        background: backgroundUploaded, 
        logo: logoUploaded,
        messages: msg,
      }
    }

    try {
      var creationCompany =  await axios.post(process.env.REACT_APP_BACK_URL + '/company', dataToSend)
   
      if (creationCompany?.data?.existIn) {
        MySwal.fire({
          title: <strong>La empresa {dataToSend.name} ya se encuentra registrada, intente con otro nombre!</strong>,
          icon: 'info'
        })
       // setUpdateIsOpen(false);
      }else{
      const result = await Swal.fire({
        title: `La empresa ${dataToSend.name} fue creada exitosamente!`,//title: <strong>La empresa {dataToSend.name} fue actualizada exitosamente!</strong>,
        icon: 'success',
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar'
      });
      
      if (result.isConfirmed) {
        const { data } = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + limit + '&skip=' + skip);
        localStorage.setItem("companies", JSON.stringify(data.companies));
        setcompaniesFetched(data.companies); 
        setIsOpen(false);
        window.location.reload();
      }
    }
      /* 
      MySwal.fire({
        title: <strong>La empresa {dataToSend.name} fue creada exitosamente!</strong>,
        icon: 'success'
      }) */
     
     /*  const { data } = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + limit + '&skip=' + skip);
      localStorage.setItem("companies", JSON.stringify(data.companies));
      setcompaniesFetched(data.companies); 
      setIsOpen(false);
      window.location.reload(); */
    } catch (err) {
      console.log(err.response.data)
   
      if (creationCompany?.data?.existIn) {
        MySwal.fire({
          title: <strong>La empresa {dataToSend.name} ya se encuentra registrada intente con otro nombre!</strong>,
          icon: 'info'
        })
       // setUpdateIsOpen(false);
      }
    }finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (formData) => {
    setLoading(true); 
    const {company, state, contact, _id} = formData
    let logoUploaded,backgroundUploaded;
    if (inputLogo && stateinputLogo){
       logoUploaded = await UploadS3(inputLogo);
    } else if (inputLogo) {
      logoUploaded = inputLogo;
    } else {
      logoUploaded = "";
    }
    //
    if (inputBackground && stateinputBackground){
     
       backgroundUploaded = await UploadS3(inputBackground);
    } else if (inputBackground) {
      
  
      backgroundUploaded  = inputBackground;
    } else {
      backgroundUploaded = "";
    }
    

    //, background , logo
   /*  const logoUploaded = await UploadS3(logo);
    const backgroundUploaded = await UploadS3(background); */
   propertiestoedit.background = backgroundUploaded;
   propertiestoedit.logo = logoUploaded; /*{
    background:backgroundUploaded , 
    logo: logoUploaded
  }*/
    const dataToSend = {
      name: company, 
      isActive: InputStatus === "active" ? true : false, 
      contact: contact,
      properties: propertiestoedit
    }

    try {
      var creationCompanyUpdate =  await axios.patch(process.env.REACT_APP_BACK_URL + '/company/' + _id, dataToSend)
   
      if (creationCompanyUpdate?.data?.existIn) {
        MySwal.fire({
          title: <strong>La empresa {dataToSend.name} ya se encuentra registrada, intente con otro nombre!</strong>,
          icon: 'info'
        })
       // setUpdateIsOpen(false);
      }else{
    

  

    const result = await Swal.fire({
      title: `La empresa ${dataToSend.name} fue actualizada exitosamente!`,//title: <strong>La empresa {dataToSend.name} fue creada exitosamente!</strong>,
      icon: 'success',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar'
    });/* 
      MySwal.fire({
        title: <strong>La empresa {dataToSend.name} ha sido actualizada exitosamente!</strong>,
        icon: 'success'
      }) */
      if (result.isConfirmed) {
        const { data } = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + limit + '&skip=' + skip);
        localStorage.setItem("companies", JSON.stringify(data.companies));
        setcompaniesFetched(data.companies);
        //setIsOpen(false);
        setUpdateIsOpen(false);
        window.location.reload();
      }
    }
    } catch (err) {
      console.log(err.response.data)
    }finally {
      setLoading(false);
    }
  };

  const seeUsers = async (_id) =>{

    const usersSidebar = document.getElementById("link0");
    usersSidebar.classList.add('list-group-item.active');
    try {
      const {data} = await axios.get(process.env.REACT_APP_BACK_URL + '/auth/getAllActiveUsers?companyId=' + _id + '&limit=' + limit + '&skip=' + skip)
      localStorage.setItem("users", JSON.stringify(data));
      localStorage.setItem("companyId", _id);
    } catch (error) {
      console.log(error.response.data)
    }
    navigate('/users');
  }

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const openUpdateModal = () => {
    setUpdateIsOpen(true);
  }

  const closeUpdateModal = () => {
    setUpdateIsOpen(false);
  }

  const editClient = (item) => {
    setpropertiestoedit(item?.properties);
//    propertiestoedit=item?.properties;
    setdataToUpdate(item)
    setInputLogo(item?.properties?.logo);
    setInputBackground(item?.properties?.background); 
    if(item.isActive == true){
    setInputStatus('active'); }
      else { setInputStatus('inactive'); }
    setUpdateIsOpen(true);
  }

  
  const onChangeBackgroundHandler = e => {
    setSteteinputBackground(true);
    setInputBackground(e.currentTarget.files[0])
  }
  const onChangeLogoHandler = e => {
    setSteteinputLogo(true);

    setInputLogo(e.currentTarget.files[0])
  }

  return (
    <div className="background-colored">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-2 justify-content-center sidebar">
          <Sidebar />
        </div>
        <div className="col-12 col-sm-9 justify-content-center datatable-container ml">
          <div className='header-container'>
            <span className='header-label'>Clientes </span>
            {role === 'superadmin' ? (
               <button className='btn btn-primary-custom' onClick={openModal}>Nueva empresa</button> 
                          

                          ) : ( 
                            <>
                            {logoIfAdmin && (
                              
                             <img src={logoIfAdmin} style={{ width: '50px', height: 'auto', display: 'flex' }}/>
                               
                            )}
                          </>
                         
                          )} 
          
           
          </div>
          {companiesFetched && !noInfo && (
            <div className='p-4 mt-4 table-container'>
              <table className="table table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col" className="th w-25 bold">Empresa</th>
                    <th scope="col" className="th w-25">Contacto</th>
                    <th scope="col" className="th w-25">Usuarios</th>
                    <th scope="col" className="th w-25">Estado</th>
                  </tr>
                </thead>
                <tbody>
                {role === 'superadmin' ? (
                    companiesFetched.map((item, key) => (
                      <tr key={key}>
                        <td scope="row"><a className='bold' onClick={() => editClient(item)}>{item.name}</a></td>
                        <td>{item.contact}</td>
                        <td>
                        <a onClick={() => seeUsers(item._id)}>
                       
    {conteoSuperAdminR && conteoSuperAdminR[item._id] ? (
      <>
      
     
     {conteoSuperAdminR[item._id]}  <i className="bi bi-person-fill m-2"></i>
    </>
    ) : (
      <> 
       {isLoadingConta ? (
      // Mostrar animación de carga aquí
      <div className="spinner-border tamanspid" role="status">
  <span className="sr-only">Loading...</span>
</div>
    ) : (
      // Mostrar número y el icono después de la carga
      <>0
     </>
      )}
      
       <i className="bi bi-person-fill m-2"></i></>
    )}
    
  </a>{/* 
                          <a onClick={() => seeUsers(item._id)}>{conteoSuperAdminR[item._id]}<i className="bi bi-person-fill m-2"></i></a> */}</td>
                        <td>{item.isActive ? "Activo" : "Inactivo"}</td>
                      </tr>
                    ))
                    ) : (
                      <tr>
                        <td scope="row"><a className='bold' onClick={() => editClient(company)}>{company.name}</a></td>
                        <td>{company.contact}</td>
                        <td><a onClick={() => seeUsers(company._id)}>{usersQuantity}<i className="bi bi-person-fill m-2"></i></a></td>
                        <td>{company.isActive ? "Activo" : "Inactivo"}</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          )}
          {noInfo && (
              <div className="container mt-4">
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-light" role="alert">
                      No se han encontrado registros
                    </div>
                  </div>
                </div>
              </div>
          )}

          <div className="row mt-4">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                {pageNumbers && pageNumbers.length > 0 &&
                  <li className="page-item"><button type="submit" className={`page-link ${skip > 0 ? '' : 'disabled'}`} 
                  onClick={() => handlePageClick(selectedPage - 1)}>{'<<'}</button></li> /* onClick={() => setSkip(skip - 1)} */
                }
                {pageNumbers.map((pageNumber, index) => (
                  <li className="page-item" key={pageNumber}><button type="submit" 
                  className={`page-link numbers ${pageNumber <= paginatorNumber ? 'show' : 'hidden'} ${selectedPage === 1 && index === 0 && loaded ? 'first-page' : ''} ${pageNumber === selectedPage ? 'selected-page' : ''}`}
                 
                  onClick={() => handlePageClick(pageNumber)} >{pageNumber}</button></li>/*  className={"page-link numbers " + (pageNumber <= 25 ? 'show' : 'hidden')} */
                ))}
                {pageNumbers && pageNumbers.length > 0 &&
                  <li className="page-item"><button type="submit" className={`page-link ${skip < pageNumbers.length - 1 ? '' : 'disabled'}`} 
                  onClick={() => handlePageClick(selectedPage + 1)}>{'>>'}</button></li> /* onClick={() => setSkip(skip + 1)} */
                }
              </ul>
            </nav>
          </div>


        {/* Modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Crear nueva empresa"
        >
           {loading ? (
        <LoadingModal />
      ) : (
        <div role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Nuevo</h5>
              
            </div>
            <hr className="disapearMobil"/>
            <div className="modal-body">
              <div className="container-fluid">

              <Formik
                initialValues={{ company: form.company, contact: form.contact, state: 'active' }} //, logo: form.logo, background: form.background
                validate={(values) => {
                  const errors = {};
                  if (!values.company) {
                    errors.company = 'Campo empresa es requerido';
                  } 

                  if (!values.contact) {
                    errors.contact = 'Campo contacto es requerido';
                  } 
                  return errors;
                }}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="row">
                    <div className="mb-3 form-group col-lg-6 col-sm-12">
                      <label htmlFor="company">Empresa <sup>*</sup> </label>
                      <Field type="text" name="company" id="company" className='form-control' placeholder="Ingresa nombre de la empresa"  maxLength={50} />
                      <ErrorMessage name="company" component="div" />
                    </div>
                    <div className="mb-3 form-group col-lg-6 col-sm-12">
                      <label htmlFor="logo">Logo</label>
                      
                      <Field type="file" name="logo" id="logo" className='form-control' accept=".jpg,.png" 
                      onChange={onChangeLogoHandler}  
                      />
                      
                      <ErrorMessage name="logo" component="div" />
                      <label htmlFor='file' className='label-sm'>Se recomienda: 1024 x 512 px</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 form-group col-lg-6 col-sm-12">
                      <label htmlFor="contact">Contacto <sup>*</sup> </label>
                      <Field type="text" name="contact" id="contact" className='form-control' placeholder="Ingresa nombre del contacto"  maxLength={30}  />
                      <ErrorMessage name="contact" component="div" />
                    </div>
                    <div className="mb-3 form-group col-lg-6 col-sm-12">
                      <label htmlFor="background">Fondo</label>
                   
                      <Field type="file" name="background" id="background" className='form-control' accept=".jpg,.png"
                     onChange={onChangeBackgroundHandler}   />
                      <ErrorMessage name="background" component="div" />
                      <label htmlFor='file' className='label-sm'>Se recomienda: 1440 x 2560 px</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 form-group col-lg-6 col-sm-12">
                      <label htmlFor="username">Estado</label>
                      <Field as="select" name="state" className='form-control select'>
                        <option value="active">Activo</option>
                        <option value="inactive">Inactivo</option>
                      </Field>
                    </div>
                  </div>
                  <hr className="disapearMobil"/>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary-custom" onClick={closeModal}>Regresar</button>
                    <button type="submit" className="btn btn-primary-custom">Guardar</button>
                  </div>
                </Form>
              </Formik>
              </div>
            </div>
          </div>
        </div>
        )}
        </Modal>

        {/* Update Modal */}
        <Modal
            isOpen={modalUpdateIsOpen}
            onRequestClose={closeUpdateModal}
            style={customStyles}
            contentLabel="Actualizar Empresa"
          >
              {loading ? (
        <LoadingModal />
      ) : (
          <div role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">{dataToUpdate?.name}</h5>{/* Nuevo */}
              </div>
              <hr />
              <div className="modal-body">
                <div className="container-fluid">
                <Formik
                  initialValues={{ _id: dataToUpdate?._id, company: dataToUpdate?.name, contact: dataToUpdate?.contact, state: dataToUpdate?.isActive ? "Activo" : "Inactivo" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.company) {
                      errors.company = 'Campo empresa es requerido';
                    } 

                    if (!values.contact) {
                      errors.contact = 'Campo contacto es requerido';
                    } 
                    return errors;
                  }}
                  onSubmit={handleUpdate}
                >
                <Form>
                  <div className="row">
                    <div className="mb-3 form-group col-lg-6 col-sm-12">
                      <label htmlFor="company">Empresa <sup>*</sup> </label>
                      <Field type="text" name="company" id="company" className='form-control' placeholder="Ingresa nombre de la empresa"  />
                      <ErrorMessage name="company" component="div" />
                    </div>
                    <div className="mb-3 form-group col-lg-6 col-sm-12">
                     <label htmlFor="logo">Logo</label>
                     <div className='row'> <div className="col-9-important">
                     
                        
                      <Field type="file" name="logo" id="logo" className='form-control' accept=".jpg,.png"  onChange={onChangeLogoHandler} />
                      </div> {inputLogo && inputLogo.name ? (
                          <p>{/* Archivo seleccionado: {logoFacebookFile.name} */}</p>
                        ) : (
                          <div className="col-3 img-c" style={{ marginTop: '-15px'}}> <div className="backgroundg" >
                          <img 
                          src={inputLogo}
                         
                          style={{ width: '50px', height: '50px',display: 'flex' }}
                        /> </div></div>
                         
                        )}
                      <ErrorMessage name="logo" component="div" />
                      <label htmlFor='file' className='label-sm'>Se recomienda: 1024 x 512 px</label>
                    </div></div> 
                  </div>
                  <div className="row">
                    <div className="mb-3 form-group col-lg-6 col-sm-12">
                      <label htmlFor="contact">Contacto <sup>*</sup> </label>
                      <Field type="text" name="contact" id="contact" className='form-control' placeholder="Ingresa nombre del contacto"  />
                      <ErrorMessage name="contact" component="div" />
                    </div>
                    <div className="mb-3 form-group col-lg-6 col-sm-12">
                    
                     
                      <label htmlFor="background">Fondo</label>
                      <div className='row'> <div className="col-9-important">
                      <Field type="file" name="background" id="background" className='form-control' accept=".jpg,.png"  onChange={onChangeBackgroundHandler}  />
                      </div> 
                      {inputBackground && inputBackground.name ? (
                          <p>{/* Archivo seleccionado: {logoFacebookFile.name} */}</p>
                        ) : (
                          <div className="col-3 img-c" style={{ marginTop: '-15px'}}> <div className="backgroundg" >
                          <img 
                          src={inputBackground}
                         
                          style={{ width: '50px', height: '50px',display: 'flex' }}
                        /> </div></div>
                         
                        )}
                      
                      
                      <ErrorMessage name="background" component="div" /> </div> 
                      <label htmlFor='file' className='label-sm'>Se recomienda: 1440 x 2560 px</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 form-group col-lg-6 col-sm-12">
                      <label htmlFor="state">Estado</label>
                      <Field as="select" name="state" className='form-control select'  value={InputStatus} onChange={(event) => { setInputStatus(event.target.value) }}>
                        <option value="active">Activo</option>
                        <option value="inactive">Inactivo</option>
                      </Field>
                    </div>
                    <div className="mb-3 form-group col-lg-6 col-sm-12">
                      <Field type="hidden" name="_id" id="_id" className='form-control' />
                    </div>
                  </div>
                  <hr />
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary-custom" onClick={closeUpdateModal}>Regresar</button>
                    <button type="submit" className="btn btn-primary-custom">Guardar</button>
                  </div>
                </Form>
                </Formik>
                </div>
              </div>
            </div>
          </div>)}
        </Modal>
        </div>
      </div>
    </div>
);
}

export default Clients;

