import React, { useContext, useEffect, useState } from "react";
import Modal from 'react-modal';
import Sidebar from "../sidebar/Sidebar";
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Formik, Form } from 'formik';
import Swal from 'sweetalert2';
import { StoreContext } from '../../context/Store';
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';
import template from '../../template.csv'
import moment from 'moment'
import { UserContext } from '../../context/UserStore';
import UploadS3 from '../../services/s3-upload';

const MySwal = withReactContent(Swal)
const customStyles = {
  content: {

    top: '25%',
    left: '25%',
    right: '25%',
    bottom: '25%',
  },

};
const customStylesParams = {
  content: {

    top: '10%',
    left: '25%',
    right: '14%',
    bottom: '10%',
  },

};;

Modal.setAppElement('#root');

function Parameters() {
  const role = localStorage.getItem("role");
  var [selectedCompany, setSelectedCompany] = useState('');
  var [nameselectedCompany, setnameSelectedCompany] = useState('');
 
 
  // initialize state of emails to inactive 
  const [stateEmailHappy, setStatusHappyEmail] = useState('inactivo');
  const [stateEmailSad, setStatusSadEmail] = useState('inactivo');
  const [stateEmailAngry, setStatusAngryEmail] = useState('inactivo');
  const [stateEmailSurprised, setStatusSurprisedEmail] = useState('inactivo');
  const [errorFormatoFaceFile, seterrorFormatoFaceFile] = useState(null);
  
  // parameters of iconografica, field and state to know if was changed
  const [logoFacebookFile, setLogoFacebookFile] = React.useState();
  const [statelogoFacebook, setSteteLogoFacebook] = useState(false);
  const [logoInstagram, setLogoInstagram] = React.useState();
  const [statelogoInstagram, setSteteLogoInstagram] = useState(false);
  const [logoTwitter, setLogoTwitter] = React.useState();
  const [statelogoTwitter, setStetelogoTwitter] = useState(false);
  const [logoYoutube, setLogoYoutube] = React.useState();
  const [statelogoYoutube, setStetelogoYoutube] = useState(false);
  const [logoLinkedIn, setLogoLinkedIn] = React.useState();
  const [statelogoLinkedIn, setStetelogoLinkedIn] = useState(false);


  const changedIcono = (e) => {
    if (e.name === "logoInstagram") {
      setSteteLogoInstagram(true);
      setLogoInstagram(e.files[0]);
    }
    if (e.name === "logoLinkedIn") {
      setStetelogoLinkedIn(true);
      setLogoLinkedIn(e.files[0]);
    }
    if (e.name === "logoYoutube") {
      setStetelogoYoutube(true);
      setLogoYoutube(e.files[0]);
    }
    if (e.name === "logoFacebook") {
      setSteteLogoFacebook(true);
      setLogoFacebookFile(e.files[0]);
    }
    if (e.name === "logoTwitter") {
      setStetelogoTwitter(true);
      setLogoTwitter(e.files[0]);
    }
  }

  const [isLoading, setIsLoading] = useState(false); //status for LOADER to know if iconografia is loading
  
  const [companieslist, setComapniesList] = useState();
  const [redirecFaceError, setredirecFaceError] = useState('');
  const [redirecInstagramError, setredirecInstagramError] = useState('');
  const [redirecTwitterError, setredirecTwitterError] = useState('');
  const [redirecYoutubeError, setredirecYoutubeError] = useState('');
  const [redirecLinkedInError, setredirecLinkedInError] = useState('');

  const [redirecFace, setRedirecFace] = useState('');
  const [redirecInstagram, setredirecInstagram] = useState('');
  const [redirecTwitter, setredirecTwitter] = useState('');
  const [redirecYoutube, setredirecYoutube] = useState('');
  const [redirecLinkedIn, setredirecLinkedIn] = useState('');
  const [happyEmailError, setHappyEmailError] = useState('');
  const [surprisedEmailError, setSurprisedEmailError] = useState('');
  const [angryEmailError, setAngryEmailError] = useState('');
  const [sadEmailError, setSadEmailError] = useState('');
  const [happyMessage, setHappyMessage] = useState('');
  const [happyEmail, setHappyEmail] = useState('');
  const [sadEmail, setSadEmail] = useState('');
  const [angryEmail, setAngryEmail] = useState('');
  const [surprisedEmail, setSurprisedEmail] = useState('');
  const [sadMessage, setSadMessage] = useState('');
  const [surprisedMessage, setSurprisedMessage] = useState('');
  const [angryMessage, setAngryMessage] = useState('');
  const { users } = useContext(StoreContext);
  const { usersDataAu } = useContext(UserContext);

  const { company } = useContext(StoreContext);
  var companyvar = useContext(StoreContext);
  const usersData = users[0];
  const companyId = localStorage.getItem("companyId");
  const companyData = JSON.parse(localStorage.getItem("company") || JSON.stringify({}));

  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenUpdate, setIsOpenUpdate] = useState(false);
  const [modalIsOpenUpload, setIsOpenUpload] = useState(false);
  const [dataToUpdate, setdataToUpdate] = useState();
  const [csvData, setCsvData] = useState();
  const [usersDataFetched, setusersDataFetched] = useState(usersData.users);
  const [skip, setSkip] = useState(0);
  const [limit] = useState(5);
  const [limit2] = useState(500);;
  const [pageNumbers, setPageNumbers] = useState([]);
  var data = JSON.parse(localStorage.getItem("companies") || JSON.stringify([{}]));;

  const companies = JSON.parse(localStorage.getItem("companies") || JSON.stringify([{}]));;
  var [primeraIteraccion, setPrimeraIteraccion] = useState(true);
  var primeraCarga;

  var dataTotal = JSON.parse(localStorage.getItem("companiestotal") || JSON.stringify([{}]));;

 

  const isAnyFieldEmpty = () => {
    return (
      selectedCompany === '' ||
      primeraIteraccion === true ||
      happyMessage === '' ||
      sadMessage === '' ||
      surprisedMessage === '' ||
      angryMessage === '' ||
      (stateEmailHappy === 'activo' && happyEmail.length === 0) ||
      (stateEmailSad === 'activo' && sadEmail.length === 0) ||
      (stateEmailAngry === 'activo' && angryEmail.length === 0) ||
      (stateEmailSurprised === 'activo' && surprisedEmail.length === 0) || sadEmailError.length != 0 || angryEmailError.length != 0 || surprisedEmailError.length != 0 || happyEmailError.length != 0
    );
  };

  const LoadingModal = () => {
    return (
      <Modal isOpen={true} style={customStyles}>
        <div className='row'>
          <div className='col-9'>Loading...</div>
          <div className='col-3'> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>

        </div>


      </Modal>
    );
  };
  const isAnyFieldLogoEmpty = () => {
    return (
      redirecFace === '' ||
      redirecInstagram === '' ||
      redirecTwitter === '' ||
      redirecYoutube === '' ||
      redirecLinkedIn === '' ||
      logoFacebookFile === '' ||
      logoInstagram === '' ||
      logoLinkedIn === '' ||
      logoTwitter === '' ||
      logoYoutube === ''
    );
  };

 

  const handleChange = (event) => {
    setPrimeraIteraccion(false); 
    const { name, value } = event.target;
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/;
    switch (name) {
      case 'redirecInstagram':

        if (!emailRegex.test(value) && value.length != 0) {
          setredirecInstagramError('');
        } else {

          setredirecInstagramError('Inserte ruta de redireccionamiento');
        }
        setredirecInstagram(value); break;
      case 'redirecTwitter':
        if (!emailRegex.test(value) && value.length != 0) {

          setredirecTwitterError('');
        } else {
          setredirecTwitterError('Inserte ruta de redireccionamiento');
        }
        setredirecTwitter(value); break;
      case 'redirecYoutube':

        if (!emailRegex.test(value) && value.length != 0) {
          setredirecYoutubeError('');
        } else {

          setredirecYoutubeError('Inserte ruta de redireccionamiento');
        }

        setredirecYoutube(value); break;
      case 'redirecLinkedIn':


        if (!emailRegex.test(value) && value.length != 0) {
          setredirecLinkedInError('');
        } else {
          setredirecLinkedInError('Inserte ruta de redireccionamiento');

        }
        setredirecLinkedIn(value); break;
      case 'redirecFace':
        if (!emailRegex.test(value) && value.length != 0) {
          setredirecFaceError('');
        } else {
          setredirecFaceError('Inserte ruta de redireccionamiento');
        }

        setRedirecFace(value);
        break;
      case 'msgh':
        setHappyMessage(value);
        break;
      case 'msgs':
        setSadMessage(value);
        break;
      case 'msgsu':
        setSurprisedMessage(value);
        break;
      case 'msga':
        setAngryMessage(value);
        break;
      case 'happyEmail':
        if (!emailRegex.test(value) && value.length != 0) {
          setHappyEmailError('Formato de correo electrónico inválido');
        } else {
          setHappyEmailError('');
        }
        setHappyEmail(value);
        break;
      case 'sadEmail':
        if (!emailRegex.test(value) && value.length != 0) {
          setSadEmailError('Formato de correo electrónico inválido');
        } else {
          setSadEmailError('');
        }
        setSadEmail(value);
        break;
      case 'angryEmail':
        if (!emailRegex.test(value) && value.length != 0) {
          setAngryEmailError('Formato de correo electrónico inválido');
        } else {
          setAngryEmailError('');
        }
        setAngryEmail(value);
        break;
      case 'surprisedEmail':
        if (!emailRegex.test(value) && value.length != 0) {
          setSurprisedEmailError('Formato de correo electrónico inválido');
        } else {
          setSurprisedEmailError('');
        }
        setSurprisedEmail(value);
        break;

         default:
        break;
    }
  };


  useEffect(() => {

    const fetchData = async () => {
      const { data } = await axios.get(process.env.REACT_APP_BACK_URL + '/auth/getAllActiveUsers?companyId=' + companyId + '&limit=' + limit + '&skip=' + skip);
      setusersDataFetched(data.users);
    }

    fetchData();

    const arrayOfPages = [];

    for (let i = 1; i <= Math.ceil(usersData.count / limit); i++) {
      arrayOfPages.push(i);
    }

    setPageNumbers(arrayOfPages);

  }, [skip])

  const [form] = useState(
    {
      identification: "",
      name: "",
      lastname: "",
      email: "",
      password: "",
      phone: "",
      genero: "masculino",
      cargo: "",
      pais: "",
      ciudad: "",
      area: "",
      phone: "",
      fechanacimiento: "",
      isActive: true,
      companyId: ""
    });

  const handleSubmit = async (formData) => {



    const {
      identification,
      name,
      lastname,
      email,
      password,
      genero,
      cargo,
      pais,
      ciudad,
      area,
      phone,
      fechanacimiento,
      isActive,
      companyId
    } = formData

    const dataToSend = {
      identification: identification,
      name: name,
      lastname: lastname,
      email: email,
      password: password,
      phone: phone,
      genero: genero,
      cargo: cargo,
      fechanacimiento: new Date(fechanacimiento),
      pais: pais,
      ciudad: ciudad,
      area: area,
      isActive: isActive,
      companyId: companyId,
    }


    try {
      await axios.post(process.env.REACT_APP_BACK_URL + '/auth/create', dataToSend)
      MySwal.fire({
        title: <strong>El usuario {dataToSend.name} fue creado exitosamente!</strong>,
        icon: 'success'
      })
      setIsOpen(false);
    } catch (err) {
      console.log(err.response.data)
    }
  };
  const btnChangeState = async (evento) => {
    setPrimeraIteraccion(false);
    if (evento.name == "stateEmailHappy") {
      setStatusHappyEmail(evento.value);
    }
    if (evento.name == "stateEmailSad") {
      setStatusSadEmail(evento.value);
    }
    if (evento.name == "stateEmailAngry") {
      setStatusAngryEmail(evento.value);
    }
    if (evento.name == "stateEmailSurprised") {
      setStatusSurprisedEmail(evento.value);
    }
  }
  const loadIconografia = async () => {


    // const {dataCo} = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all');
    const iconografiaData = await axios.get(process.env.REACT_APP_BACK_URL + '/parameters');
    if (iconografiaData?.data?.urlImg) {
      setRedirecFace(iconografiaData?.data?.urlImg?.facebook);
      setredirecInstagram(iconografiaData?.data?.urlImg?.instagram);
      setredirecTwitter(iconografiaData?.data?.urlImg?.twitter);
      setredirecYoutube(iconografiaData?.data?.urlImg?.youtube);
      setredirecLinkedIn(iconografiaData?.data?.urlImg?.linkedin);
    }
    if (iconografiaData?.data?.images) {
      setLogoFacebookFile(iconografiaData?.data?.images?.logofacebook);
      setLogoInstagram(iconografiaData?.data?.images?.logoinstagram);
      setLogoTwitter(iconografiaData?.data?.images?.logotwitter);
      setLogoYoutube(iconografiaData?.data?.images?.logoyoutube);
      setLogoLinkedIn(iconografiaData?.data?.images?.logolinkedin);
    }
  }
  const btnChange = async (idCompanySelected) => {
    setPrimeraIteraccion(true);
    setSelectedCompany(idCompanySelected);
    // const {dataCo} = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all');
    const companiesResponse = await axios.get(process.env.REACT_APP_BACK_URL + '/company/search/' + idCompanySelected);
    if (companiesResponse?.data?.properties?.messages) {
      setnameSelectedCompany(companiesResponse?.data?.name);
      const happyMessageFromResponse = companiesResponse.data.properties.messages.happy;
      setHappyMessage(happyMessageFromResponse);
      setSadMessage(companiesResponse.data.properties.messages.sad);
      setSurprisedMessage(companiesResponse.data.properties.messages.surprised);
      setAngryMessage(companiesResponse.data.properties.messages.angry);//happyMessage
      if (companiesResponse?.data?.properties?.emailEmotions?.stateEmailHappy) {
        setStatusHappyEmail(companiesResponse?.data?.properties?.emailEmotions?.stateEmailHappy);
      }
      else {
        //setStatusHappyEmail("activo");
        setStatusHappyEmail("inactivo");
      }
      if (companiesResponse?.data?.properties?.emailEmotions?.stateEmailSad) {
        setStatusSadEmail(companiesResponse?.data?.properties?.emailEmotions?.stateEmailSad);
      } else {
        //  setStatusSadEmail("activo");
        setStatusSadEmail("inactivo");
      }
      if (companiesResponse?.data?.properties?.emailEmotions?.stateEmailAngry) {
        setStatusAngryEmail(companiesResponse?.data?.properties?.emailEmotions?.stateEmailAngry);

      } else {
        //* setStatusAngryEmail("activo");
        setStatusAngryEmail("inactivo");
      }
      if (companiesResponse?.data?.properties?.emailEmotions?.stateEmailSurprised) {
        setStatusSurprisedEmail(companiesResponse?.data?.properties?.emailEmotions?.stateEmailSurprised);
      } else {
        // setStatusSurprisedEmail("activo");
        setStatusSurprisedEmail("inactivo");
      }



    } else {
      setHappyMessage("");
      setSadMessage("");
      setSurprisedMessage("");
      setAngryMessage("");
      setStatusHappyEmail("activo");
      setStatusSadEmail("activo");
      setStatusAngryEmail("activo");
      setStatusSurprisedEmail("activo");
    }

    if (companiesResponse?.data?.properties?.emailEmotions) {
      setHappyEmail(companiesResponse.data.properties.emailEmotions.emailHappy);
      setSadEmail(companiesResponse.data.properties.emailEmotions.emailSad);
      setAngryEmail(companiesResponse.data.properties.emailEmotions.emailAngry);
      setSurprisedEmail(companiesResponse.data.properties.emailEmotions.emailSurprised);
    } else {
      setHappyEmail("");
      setSadEmail("");
      setAngryEmail("");
      setSurprisedEmail("");
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + 300 + '&skip=' + 0);
      localStorage.setItem("companiestotal", JSON.stringify(response.data.companies));
      dataTotal = response.data.companies;
      setComapniesList(response);
    };
    fetchData();
  }, [])
  useEffect(() => {



    if (role === "admin" && !selectedCompany && companyData) {
      btnChange(companyData._id);
      primeraIteraccion = true;

    }






  }, [selectedCompany, companyData.companyData]);


  const btnGuardarIconografia = async () => {
    setIsLoading(true);
    const iconsToSend = {
      facebook: redirecFace,
      instagram: redirecInstagram,
      twitter: redirecTwitter,
      youtube: redirecYoutube,
      linkedin: redirecLinkedIn
    };

    //  validate if the files are selected 
    //  and validate if are changed 
    let facebookUploaded, instagramUploaded, logoTwitterUploaded, logoYoutubeUploaded, logoLinkedInUploaded;



    if (logoFacebookFile && statelogoFacebook) {
      facebookUploaded = await UploadS3(logoFacebookFile)
      setSteteLogoFacebook(false);
    } else if (logoFacebookFile) { facebookUploaded = logoFacebookFile; } else {
      facebookUploaded = "";
    }

    if (logoInstagram && statelogoInstagram) {
      instagramUploaded = await UploadS3(logoInstagram)
      setSteteLogoInstagram(false);
    } else if (logoInstagram) {

      instagramUploaded = logoInstagram;
    } else {
      instagramUploaded = "";
    }





    if (logoTwitter && statelogoTwitter) {
      logoTwitterUploaded = await UploadS3(logoTwitter)
      setStetelogoTwitter(false);
    } else if (logoTwitter) {
      logoTwitterUploaded = logoTwitter;
    } else {
      logoTwitterUploaded = "";
    }

    if (logoYoutube && statelogoYoutube) {
      logoYoutubeUploaded = await UploadS3(logoYoutube)
      setStetelogoYoutube(false);
    } else if (logoYoutube) {
      logoYoutubeUploaded = logoYoutube;
    } else {
      logoYoutubeUploaded = "";
    }

    if (logoLinkedIn && statelogoLinkedIn) {
      logoLinkedInUploaded = await UploadS3(logoLinkedIn)
      setStetelogoLinkedIn(false);
    } else if (logoLinkedIn) { logoLinkedInUploaded = logoLinkedIn; } else {
      logoLinkedInUploaded = "";
    }


    const fileLogo = { logofacebook: facebookUploaded, logoinstagram: instagramUploaded, logotwitter: logoTwitterUploaded, logoyoutube: logoYoutubeUploaded, logolinkedin: logoLinkedInUploaded };
    const fileLogoOR = {
      logofacebook: "https://dpersonas.s3.amazonaws.com/app_mobil/imagenes/Facebook+icon.png",
      logoinstagram: "https://dpersonas.s3.amazonaws.com/app_mobil/imagenes/Instagram+icon.png",
      logotwitter: "https://dpersonas.s3.amazonaws.com/app_mobil/imagenes/Twitter+Icon.png",
      logoyoutube: "https://dpersonas.s3.amazonaws.com/app_mobil/imagenes/Youtube+Icon.png",
      logolinkedin: "https://dpersonas.s3.amazonaws.com/app_mobil/imagenes/LinkedIn+Icon.png",
    }
    try {
      await axios.patch(process.env.REACT_APP_BACK_URL + '/parameters/parameterUrl', iconsToSend);
      await axios.patch(process.env.REACT_APP_BACK_URL + '/parameters/parameterLogo', fileLogo); // fileLogoOR
      //await axios.patch(process.env.REACT_APP_BACK_URL + '/company/updateEmail/'+selectedCompany, dataEmailSend); 
      setIsLoading(false);
      MySwal.fire({
        title: <strong>Parámetros actualizados exitosamente!</strong>,
        icon: 'success'
      })

      closeModal();
      //  setIsOpen(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err.response.data)
    }
  }

  const btnGuardar = async () => {
    const dataSend = {
      happy: happyMessage, sad: sadMessage,
      surprised: surprisedMessage,
      angry: angryMessage
    }; //message:{}



    const dataEmailSend = {
      emailHappy: happyEmail, emailSad: sadEmail,
      emailSurprised: surprisedEmail,
      emailAngry: angryEmail, stateEmailHappy: stateEmailHappy, stateEmailSad: stateEmailSad,
      stateEmailAngry: stateEmailAngry, stateEmailSurprised: stateEmailSurprised,
    }


    try {
      await axios.patch(process.env.REACT_APP_BACK_URL + '/company/updateProperties/' + selectedCompany, dataSend);
      await axios.patch(process.env.REACT_APP_BACK_URL + '/company/updateEmail/' + selectedCompany, dataEmailSend);
      MySwal.fire({
        title: <strong>La empresa {nameselectedCompany} ha sido actualizada exitosamente!</strong>,
        icon: 'success'
      })
      setIsOpen(false);
      setPrimeraIteraccion(true);
    } catch (err) {
      console.log(err.response.data)
    }

  }

  const handleUpdate = async (formData) => {
    const {
      identification,
      name,
      lastname,
      email,
      password,
      genero,
      cargo,
      pais,
      ciudad,
      area,
      phone,
      fechanacimiento,
      isActive,
      companyId
    } = formData

    const dataToSend = {
      identification: identification,
      name: name,
      lastname: lastname,
      email: email,
      password: password,
      phone: phone,
      genero: genero,
      cargo: cargo,
      fechanacimiento: new Date(fechanacimiento),
      pais: pais,
      ciudad: ciudad,
      area: area,
      isActive: isActive,
      companyId: companyId,
    }

    const id = formData._id
    try {
      await axios.patch(process.env.REACT_APP_BACK_URL + '/auth/update/' + id, dataToSend)
      MySwal.fire({
        title: <strong>El usuario {formData.name} ha sido actualizado exitosamente!</strong>,
        icon: 'success'
      })
      setIsOpenUpdate(false);
    } catch (err) {
      console.log(err.response.data)
    }
  };



  const goBack = () => {
    navigate(-1);
  };

  const openModal = () => {
    loadIconografia();
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModalUpdate = () => {
    setIsOpenUpdate(true);
  };

  const closeModalUpdate = () => {
    setIsOpenUpdate(false);
  };

  const openUploadModal = () => {
    setIsOpenUpload(true);
  };

  const closeUploadModal = () => {
    setIsOpenUpload(false);
  };

  const editUser = (item) => {
    setdataToUpdate(item)
    setIsOpen(true);
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const contents = reader.result;
      setCsvData(parseCsvData(contents));
    };

    reader.readAsText(file);
  }

  const download = () => {
    const file = template;
    const link = document.createElement('a');
    link.href = file;
    link.download = 'template-to-upload-users.csv';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  }

  const parseCsvData = (csvContent) => {
    const rows = csvContent.split('\n');
    const headers = rows[0].split(';');
    //const data = [];

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(';');
      const rowData = {};

      for (let j = 0; j < headers.length; j++) {
        rowData[headers[j]] = row[j];
      }

      data.push(rowData);
    }

    return data;
  };

  const handleUpload = () => {
    csvData.forEach(async (element) => {
      if (!element.password) return

      const dataToSend = {
        identification: element?.identification,
        name: element?.name,
        lastname: element?.lastname,
        email: element?.email,
        password: element?.password,
        phone: element?.phone,
        genero: element?.genero,
        cargo: element?.cargo,
        pais: element?.pais,
        ciudad: element?.ciudad,
        area: element?.area,
        isActive: true,
        companyId: companyData
      }
      try {
        await axios.post(process.env.REACT_APP_BACK_URL + '/auth/create', dataToSend)
        MySwal.fire({
          title: <strong>Usuarios cargados exitosamente!</strong>,
          icon: 'success'
        })
        setIsOpenUpload(false);
      } catch (err) {
        console.log(err.response.data)
      }
    });
  };

  return (



    <div className="background-colored">
      <div className="row justify-content-center"><div className="col-12 col-sm-2 justify-content-center sidebar">
        <Sidebar />
      </div>
        <div className="col-12 col-sm-9 justify-content-center datatable-container ml">
          <div className='header-container'>
            <span className='header-label'>Administración de Parámetros</span>
            {role === 'superadmin' ? (
              <button className='btn btn-secondary-dark' onClick={openModal}>Iconografía</button>
            ) : (<></>)}
            {/*  {data && data.length > 0 ? (
                     
                       
                     <button className='btn btn-secondary-dark' onClick={openModal}>Iconografía</button>
                     
                    ):( <></>)} */}
            {/*  <button className='btn btn-secondary-dark' onClick={openModal}>Iconografía</button> */}
          </div>
          <div className="p-2">
            <Formik
              initialValues={{ companyId: form.companyId, month: form.month, year: form.year, selectedCompany: selectedCompany }}
              validate={(values) => {
                const errors = {};
                if (!values.companyId) {
                  errors.company = 'Seleccione la empresa';
                }

                if (!values.month) {
                  errors.month = 'Seleccione el mes';
                }
                return errors;
              }}
              onSubmit={handleSubmit}
            >
              <Form className="row">
                <div className="col-sm-3">
                  <Field as="select" id="companyId" name="companyId" className='form-select fs-empresa' value={selectedCompany} onChange={(event) => { btnChange(event.target.value) }}>

                    {role === 'superadmin' ? (
                      <>
                        <option value="">Seleccione empresa</option>
                        {dataTotal.map((company, key) => (
                          <option value={company._id} key={key} >{company.name}</option>
                        ))}
                      </>
                    ) : (
                      <option value={companyData._id}>{companyData.name}</option>
                    )}
                  </Field>
                </div>
                <div className="col-sm-3">
                  <button className='btn gparameter m-0 pl-4 pr-4 pt-2 pb-2 btn btn-primary-custom' onClick={btnGuardar} disabled={isAnyFieldEmpty()}>Guardar</button> {/* disabled={isAnyFieldEmpty} */}
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-7 col-sm-12 p-3 ">
                      <div className="formparameter formparameter">
                        <h1 className="titelform rem09">Envío de notificaciones</h1>
                        <div className="row pt-2">
                          <div className="col-4 textreaction " >Reacción feliz</div>
                          <div className="mb-3 form-group col-6">
                            <Field
                              type="text"
                              name="happyEmail"
                              id="happyEmail"
                              className="form-control rem09"
                              placeholder="Ingresa correo" maxLength={60}
                              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                              value={happyEmail}
                              onChange={handleChange}
                            />
                            {happyEmail === '' && stateEmailHappy === 'activo' && selectedCompany && (
                              <div className="error-message">Ingresa correo para feliz.</div>
                            )}
                            {happyEmailError && <div className="error-message">{happyEmailError}</div>}
                          </div>
                          <div className="col-2 pl5">
                            <Field as="select" name="stateEmailHappy" className='form-control select btnactiv' value={stateEmailHappy} onChange={(event) => { btnChangeState(event.target) }}>
                              <option value="activo">Activo</option>
                              <option value="inactivo">Inactivo</option>
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 textreaction " >Reacción triste</div>
                          <div className="mb-3 form-group col-6">

                            <Field
                              type="text"
                              name="sadEmail"
                              id="sadEmail"
                              className="form-control rem09" maxLength={60}
                              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                              placeholder="Ingresa correo"
                              value={sadEmail}
                              onChange={handleChange}


                            />
                            {sadEmail === '' && stateEmailSad === 'activo' && selectedCompany && (
                              <div className="error-message">Ingresa correo para triste.</div>
                            )}
                            {sadEmailError && <div className="error-message">{sadEmailError}</div>}

                          </div>
                          <div className="col-2 pl5">
                            <Field as="select" name="stateEmailSad" className='form-control select btnactiv' value={stateEmailSad} onChange={(event) => { btnChangeState(event.target) }}>
                              <option value="activo">Activo</option>
                              <option value="inactivo">Inactivo</option>
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 textreaction" >Reacción enojado</div>
                          <div className="mb-3 form-group col-6">

                            <Field
                              type="text"
                              name="angryEmail"
                              id="angryEmail"
                              className="form-control rem09" maxLength={60}
                              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                              placeholder="Ingresa correo"
                              value={angryEmail}
                              onChange={handleChange}

                            />
                            {angryEmail === '' && stateEmailAngry === 'activo' && selectedCompany && (
                              <div className="error-message">Ingresa correo para enojado.</div>
                            )}
                            {angryEmailError && <div className="error-message">{angryEmailError}</div>}
                          </div>
                          <div className="col-2 pl5">
                            <Field as="select" name="stateEmailAngry" className='form-control select btnactiv' value={stateEmailAngry} onChange={(event) => { btnChangeState(event.target) }} >
                              <option value="activo">Activo</option>
                              <option value="inactivo">Inactivo</option>
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 textreaction" >Reacción sorprendido</div>
                          <div className="mb-3 form-group col-6">

                            <Field
                              type="text"
                              name="surprisedEmail"
                              id="surprisedEmail"
                              className="form-control rem09" maxLength={60}
                              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                              placeholder="Ingresa correo"
                              value={surprisedEmail}
                              onChange={handleChange}
                            />
                            {surprisedEmail === '' && stateEmailSurprised === 'activo' && selectedCompany && (
                              <div className="error-message">Ingresa correo para sorprendido.</div>
                            )}
                            {surprisedEmailError && <div className="error-message">{surprisedEmailError}</div>}
                          </div>
                          <div className="col-2 pl5">
                            <Field as="select" name="stateEmailSurprised" className='form-control select btnactiv ' value={stateEmailSurprised} onChange={(event) => { btnChangeState(event.target) }} >
                              <option value="activo">Activo</option>
                              <option value="inactivo">Inactivo</option>
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-12  p-3 ">
                      <div className="formparameter formparameter px-2">
                        <h1 className="titelform rem09">Mensaje Final por reacción</h1>

                        <div className="row pt-2"><div className="mb-3 form-group col-12">

                          <Field
                            type="text"
                            name="msgh"
                            id="msgh"
                            className="form-control rem09" maxLength={100}
                            placeholder="Ingrese mensaje final para la reacción feliz"
                            value={happyMessage}
                            onChange={handleChange}
                          />
                          {happyMessage === '' && selectedCompany && (
                            <div className="error-message">Por favor ingrese un mensaje para la reacción feliz.</div>
                          )}
                          <ErrorMessage name="name" component="div" />
                        </div></div>
                        <div className="row"><div className="mb-3 form-group col-12">

                          <Field
                            type="text"
                            name="msgs"
                            id="msgs"
                            className="form-control rem09" maxLength={100}
                            placeholder="Ingrese mensaje final para la reacción triste"
                            value={sadMessage}
                            onChange={handleChange}
                          />
                          {sadMessage === '' && selectedCompany && (
                            <div className="error-message">Por favor ingrese un mensaje para la reacción triste.</div>
                          )}
                          <ErrorMessage name="name" component="div" />
                        </div></div>
                        <div className="row"><div className="mb-3 form-group col-12">

                        <Field
                          type="text"
                          name="msga"
                          id="msga"
                          className="form-control rem09" maxLength={100}
                          placeholder="Ingrese mensaje final para la reacción enojado"
                          value={angryMessage}
                          onChange={handleChange}
                        />
                        {angryMessage === '' && selectedCompany && (
                          <div className="error-message">Por favor ingrese un mensaje para la reacción enojado.</div>
                        )}
                        <ErrorMessage name="name" component="div" />
                        </div>

                        </div>
                        <div className="row"><div className="mb-3 form-group col-12">

                          <Field
                            type="text"
                            name="msgsu"
                            id="msgsu"
                            className="form-control rem09" maxLength={100}
                            placeholder="Ingrese mensaje final para la reacción sorprendido"
                            value={surprisedMessage}
                            onChange={handleChange}
                          />
                            {surprisedMessage === '' && selectedCompany && (
                            <div className="error-message">Por favor ingrese un mensaje para la reacción sorprendido.</div>
                          )}
                          <ErrorMessage name="name" component="div" />
                        </div></div>

                      </div>
                    </div>
                  </div>






                  <div className="header-container">
                    <div>
                      {/*  <a className="arrow" onClick={goBack}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                      </a> */}

                    </div>

                  </div>

                </div>


              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStylesParams}
        contentLabel="Modificar iconografia"
      >
        {isLoading ? (
          <LoadingModal />
        ) : (

          <div role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Iconografía
                </h5>
              </div>
              <hr class="disapearMobil" />
              <div className="modal-body">
                <div className="container-fluid">

                  <Formik
                    initialValues={{
                      name: form.name,
                      lastname: form.lastname,
                      email: form.email,
                      phone: form.phone,
                      isActive: true,
                      genero: form.genero,
                      cargo: form.cargo,
                      pais: form.pais,
                      ciudad: form.ciudad,
                      area: form.area,
                      identification: form.identification,
                      fechanacimiento: form.fechanacimiento,
                      password: form.password,
                      isActive: true,
                      companyId: companyData
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.company = "Campo email es requerido";
                      }

                      if (!values.phone) {
                        errors.phone = "Campo phone es requerido";
                      }
                      return errors;
                    }}
                    onSubmit={handleSubmit}
                  >

                    <Form>
                      <div className="row">
                        <div className="mb-3 form-group col-2 centradoTextosParams">
                          <label htmlFor="company" className="rem09">
                            Facebook <sup>*</sup>{" "}
                          </label>
                        </div>
                        <div className="mb-3 form-group col-lg-5 col-sm-12 ">
                          <div className="col-9-important">
                            <Field type="file" name="logoFacebook" id="logoFacebook" className='form-control align-c rem09' accept=".jpg,.png" onChange={(event) => changedIcono(event.target)} />{/* //setLogoFacebookFile( .files[0])*/}
                          </div>
                          {logoFacebookFile && logoFacebookFile.name ? (
                            <p>{/* Archivo seleccionado: {logoFacebookFile.name} */}</p>
                          ) : (
                            <div className="col-3 img-c" style={{}}> <div className="backgroundg" >
                              <img
                                src={logoFacebookFile}
                                alt="Logo Facebook"
                                style={{ width: '50px', height: '50px', display: 'flex' }}
                              /> </div></div>

                          )}
                          <ErrorMessage name="logoFacebook" component="div" />
                        </div>
                        <div className="mb-3 form-group col-lg-5 col-sm-12 centradoTextosParams">
                          <Field
                            type="text"
                            name="redirecFace"
                            id="redirecFace"
                            className="form-control rem09"
                            placeholder="Ingresa redireccionamiento a red social"
                            value={redirecFace}
                            onChange={handleChange}
                          />
                          {redirecFaceError && <div className="error-message">{redirecFaceError}</div>}

                          <ErrorMessage name="redirecFace" component="div" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 form-group col-2 centradoTextosParams">
                          <label htmlFor="company" className="rem09">
                            Instagram <sup>*</sup>{" "}
                          </label>
                        </div>
                        <div className="mb-3 form-group col-lg-5 col-sm-12">
                          <div className="col-9-important">
                            <Field type="file" name="logoInstagram" id="logoInstagram" className='form-control align-c rem09' accept=".jpg,.png" onChange={(event) => changedIcono(event.target)} />{/* setLogoInstagram(event.target.files[0]) */}
                          </div> {logoInstagram && logoInstagram.name ? (
                            <p>{/* Archivo seleccionado: {logoInstagram.name} */}</p>
                          ) : (
                            <div className="col-3 img-c" style={{}}> <div className="backgroundg" >
                              <img
                                src={logoInstagram}
                                alt="Logo Facebook"
                                style={{ width: '50px', height: '50px', display: 'flex' }}
                              /> </div></div>

                          )}
                          {/*   {logoInstagram && <p>Archivo seleccionado: {logoInstagram.name}</p>} */}
                          <ErrorMessage name="logoInstagram" component="div" />
                        </div>
                        <div className="mb-3 form-group col-lg-5 col-sm-12 centradoTextosParams">
                          <Field
                            type="text"
                            name="redirecInstagram"
                            id="redirecInstagram"
                            className="form-control rem09"
                            placeholder="Ingresa redireccionamiento a red social"
                            value={redirecInstagram}
                            onChange={handleChange}
                          />
                          {redirecInstagramError && <div className="error-message">{redirecInstagramError}</div>}



                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 form-group col-2 centradoTextosParams">
                          <label htmlFor="company" className="rem09">
                            Twitter <sup>*</sup>{" "}
                          </label>
                        </div>
                        <div className="mb-3 form-group col-lg-5 col-sm-12">
                          <div className="col-9-important">

                            <Field type="file" name="logoTwitter" id="logoTwitter" className='form-control align-c rem09' accept=".jpg,.png" onChange={(event) => changedIcono(event.target)} />{/* setLogoTwitter(event.target.files[0]) */}
                          </div>  {logoTwitter && logoTwitter.name ? (
                            <p>{/* Archivo seleccionado: {logoTwitter.name} */}</p>
                          ) : (
                            <div className="col-3 img-c" style={{}}> <div className="backgroundg" >
                              <img
                                src={logoTwitter}
                                alt="Logo Facebook"
                                style={{ width: '50px', height: '50px', display: 'flex' }}
                              /> </div></div>

                          )}

                          {/*  {logoTwitter && <p>Archivo seleccionado: {logoTwitter.name}</p>} */}
                          <ErrorMessage name="logoTwitter" component="div" />
                        </div>
                        <div className="mb-3 form-group col-lg-5 col-sm-12 centradoTextosParams">
                          <Field
                            type="text"
                            name="redirecTwitter"
                            id="redirecTwitter"
                            className="form-control rem09"
                            placeholder="Ingresa redireccionamiento a red social"
                            value={redirecTwitter}
                            onChange={handleChange}
                          />
                          {redirecTwitterError && <div className="error-message">{redirecTwitterError}</div>}
                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 form-group col-2 centradoTextosParams">
                          <label htmlFor="company" className="rem09">
                            Youtube <sup>*</sup>{" "}
                          </label>
                        </div>
                        <div className="mb-3 form-group col-lg-5 col-sm-12">
                          <div className="col-9-important">


                            <Field type="file" name="logoYoutube" id="logoYoutube" className='form-control  align-c rem09' accept=".jpg,.png" onChange={(event) => changedIcono(event.target)} /> {/* setLogoYoutube(event.target.files[0]) */}
                          </div>
                          {logoYoutube && logoYoutube.name ? (
                            <p>{/* Archivo seleccionado: {logoYoutube.name} */}</p>
                          ) : (
                            <div className="col-3 img-c" style={{}}> <div className="backgroundg" >
                              <img
                                src={logoYoutube}
                                alt="Logo Facebook"
                                style={{ width: '50px', height: '50px', display: 'flex' }}
                              /> </div></div>

                          )}

                          {/* {logoYoutube && <p>Archivo seleccionado: {logoYoutube.name}</p>} */}
                          <ErrorMessage name="logoYoutube" component="div" />
                        </div>
                        <div className="mb-3 form-group col-lg-5 col-sm-12 centradoTextosParams">
                          <Field
                            type="text"
                            name="redirecYoutube"
                            id="redirecYoutube"
                            className="form-control rem09"
                            placeholder="Ingresa redireccionamiento a red social"
                            value={redirecYoutube}
                            onChange={handleChange}
                          />

                          {redirecYoutubeError && <div className="error-message">{redirecYoutubeError}</div>}



                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 form-group col-2 centradoTextosParams">
                          <label htmlFor="company" className="rem09">
                            LinkedIn <sup>*</sup>{" "}
                          </label>
                        </div>
                        <div className="mb-3 form-group col-lg-5 col-sm-12">
                          <div className="col-9-important">

                            <Field type="file" name="logoLinkedIn" id="logoLinkedIn" className='form-control align-c rem09' accept=".jpg,.png" onChange={(event) => changedIcono(event.target)} /> {/* setLogoLinkedIn(event.target.files[0]) */}
                          </div>  {logoLinkedIn && logoLinkedIn.name ? (
                            <p>{/* Archivo seleccionado: {logoLinkedIn.name} */}</p>
                          ) : (
                            <div className="col-3 img-c" style={{}}> <div className="backgroundg" >
                              <img
                                src={logoLinkedIn}
                                alt="Logo Facebook"
                                style={{ width: '50px', height: '50px', display: 'flex' }}
                              /> </div></div>


                          )}{/* 
                        {logoLinkedIn && <p>Archivo seleccionado: {logoLinkedIn.name}</p>} */}
                          <ErrorMessage name="logoLinkedIn" component="div" />
                        </div>
                        <div className="mb-3 form-group col-lg-5 col-sm-12 centradoTextosParams">
                          <Field
                            type="text"
                            name="redirecLinkedIn"
                            id="redirecLinkedIn"
                            className="form-control rem09"
                            placeholder="Ingresa redireccionamiento a red social"
                            value={redirecLinkedIn}
                            onChange={handleChange}
                          />
                          {redirecLinkedInError && <div className="error-message">{redirecLinkedInError}</div>}

                        </div>
                      </div>


                      <hr class="disapearMobil" />
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary-custom"
                          onClick={closeModal}
                        >
                          Regresar
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary-custom" onClick={btnGuardarIconografia} disabled={isAnyFieldLogoEmpty() || isLoading}
                        >
                          Guardar
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>)}
      </Modal>

    </div>
  );
}
export default Parameters;
