import React, { useContext, useEffect, useState } from 'react';
import Sidebar from '../sidebar/Sidebar'
import Modal from 'react-modal';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import IsMobile from '../../services/is-mobile--checker';

Modal.setAppElement('#root');


function Companies() {
  const [loaded, setLoaded] = useState(false);
  const [paginatorNumber, setPaginatorNumber] = useState(15);

  useEffect(() => {
    const isMobile = IsMobile();
    if(isMobile){
      setPaginatorNumber(5);
    }
    setLoaded(true);
  }, []);

  const [isLoadingConta, setIsLoadingConta] = useState(true);
 
  const companies = JSON.parse(localStorage.getItem("companies") || JSON.stringify([{}]));
  const companiestotal = JSON.parse(localStorage.getItem("companiestotal") || JSON.stringify([{}]));
  const [conteoSuperAdminR, setConteoSuperAdminR] = useState();
  var conteoSuperAdmin = [];
  const [skip, setSkip] = useState(0);
  const [limit] = useState(5);

  useEffect(() => {
    const fetchConteoSuperAdmin = async () => {
      const conteoSuperAdmin = {};
      for (const company of companiestotal) {
        const companyId = company._id;
        const response = await axios.get(process.env.REACT_APP_BACK_URL + '/admin/getAllUsersBycompanyId?companyId=' + companyId);
        conteoSuperAdmin[companyId] = response.data.count;
      }
      setConteoSuperAdminR(conteoSuperAdmin);
       setIsLoadingConta(false);
    };
  
    fetchConteoSuperAdmin();
  }, []);
 
  const companiesCount = JSON.parse(localStorage.getItem("companies-count") || JSON.stringify([{}]));
 const role = localStorage.getItem("role");

  const company = JSON.parse(localStorage.getItem("company") || "{}");

  const adminsQuantity = localStorage.getItem("admins-quantity");
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState(1);
  const [InputStatus, setInputStatus] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalUpdateIsOpen, setUpdateIsOpen] = useState(false);
  const [dataToUpdate, setdataToUpdate] = useState();
  const [pageNumbers, setPageNumbers] = useState([]);
  const [propertiestoedit, setpropertiestoedit] = useState();
  const [noInfo, setNoInfo] = useState(false);
  
  const [logoIfAdmin, setLogoIfAdmin] = useState("");
  const [inputLogo, setInputLogo] =  React.useState();//=useState();
  const [companiesFetched, setcompaniesFetched] = useState();
  const [inputBackground, setInputBackground] = React.useState();


  const handlePageClick = (pageNumber) => {
    setSelectedPage(pageNumber);
    setSkip(pageNumber - 1);
  };
  useEffect(() => {
    setLogoIfAdmin(company?.properties?.logo);
    const fetchData = async () => {
      const { data } = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + limit + '&skip=' + skip);
      localStorage.setItem("companies", JSON.stringify(data.companies));

      const newCompaniesReloadInit = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + limit + '&skip=' + skip);
      setcompaniesFetched(newCompaniesReloadInit.data.companies);
    }

    fetchData();
    const arrayOfPages = [];
    for (let i = 1; i <= Math.ceil(companiesCount / limit); i++) {
      arrayOfPages.push(i);
    }
    setPageNumbers(arrayOfPages);

  }, [skip])



  const seeAdmins = async (_id) =>{

    const usersSidebar = document.getElementById("link0");
    usersSidebar.classList.add('list-group-item.active');
    try {
      const {data} = await axios.get(process.env.REACT_APP_BACK_URL + '/admin/getAllUsersBycompanyId?companyId=' + _id)
      localStorage.setItem("admins", JSON.stringify(data));
      localStorage.setItem("companyId", _id);
    } catch (error) {
      console.log(error.response.data)
    }
    navigate('/admins');
  }


  return (
    <div className="background-colored">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-2 justify-content-center sidebar">
          <Sidebar />
        </div>
        <div className="col-12 col-sm-9 justify-content-center datatable-container ml">
          <div className='header-container'>
            <span className='header-label'>Empresas</span>
          
           
          </div>
          {companiesFetched && !noInfo && (
            <div className='p-4 mt-4 table-container'>
              <table className="table table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col" className="th w-25 bold">Empresa</th>
                    <th scope="col" className="th w-25">Usuarios Admin</th>
                  </tr>
                </thead>
                <tbody>
                {role === 'superadmin' ? (
                    companiesFetched.map((item, key) => (
                      <tr key={key}>
                        <td scope="row">{item.name}</td>
                        <td>
                        <a onClick={() => seeAdmins(item._id)}>
                                            
                          {conteoSuperAdminR && conteoSuperAdminR[item._id] ? (
                            <>
                            
                          
                          {conteoSuperAdminR[item._id]}  <i className="bi bi-person-fill m-2"></i>
                          </>
                          ) : (
                            <> 
                            {isLoadingConta ? (
                            // Mostrar animación de carga aquí
                            <div className="spinner-border tamanspid" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                          ) : (
                            // Mostrar número y el icono después de la carga
                            <>0
                          </>
                            )}
                            
                            <i className="bi bi-person-fill m-2"></i></>
                          )}
                          
                        </a>{}</td>
                      </tr>
                    ))
                    ) : (
                      <tr>
                        <td scope="row">{company.name}</td>
                        <td><a onClick={() => seeAdmins(company._id)}>{adminsQuantity}<i className="bi bi-person-fill m-2"></i></a></td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          )}
          {noInfo && (
              <div className="container mt-4">
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-light" role="alert">
                      No se han encontrado registros
                    </div>
                  </div>
                </div>
              </div>
          )}

          <div className="row mt-4">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                {pageNumbers && pageNumbers.length > 0 &&
                  <li className="page-item"><button type="submit" className={`page-link ${skip > 0 ? '' : 'disabled'}`} 
                  onClick={() => handlePageClick(selectedPage - 1)}>{'<<'}</button></li> /* onClick={() => setSkip(skip - 1)} */
                }
                {pageNumbers.map((pageNumber, index) => (
                  <li className="page-item" key={pageNumber}><button type="submit" 
                  className={`page-link numbers ${pageNumber <= paginatorNumber ? 'show' : 'hidden'} ${selectedPage === 1 && index === 0 && loaded ? 'first-page' : ''} ${pageNumber === selectedPage ? 'selected-page' : ''}`}
                 
                  onClick={() => handlePageClick(pageNumber)} >{pageNumber}</button></li>/*  className={"page-link numbers " + (pageNumber <= 25 ? 'show' : 'hidden')} */
                ))}
                {pageNumbers && pageNumbers.length > 0 &&
                  <li className="page-item"><button type="submit" className={`page-link ${skip < pageNumbers.length - 1 ? '' : 'disabled'}`} 
                  onClick={() => handlePageClick(selectedPage + 1)}>{'>>'}</button></li> /* onClick={() => setSkip(skip + 1)} */
                }
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
);
}

export default Companies;

