import React, { useContext } from 'react'
import {
    Routes, 
    Route,
    Navigate
} from 'react-router-dom'

import Login from '../components/login/Login'
import Protected from '../components/protected'
import Users from '../components/users/Users'
import Report from '../components/reports/Reports'

import Parameters from '../components/parameters/Parameters'
import Clients from '../components/clients/Clients'
import Dashboard from '../components/dashboard/Dashboard'
import Admin from '../components/admin/Admin'
import Companies from '../components/admin/Companies'
import Notifications from '../components/notifications/Notifications'
export const AppRouter = () => {


    return (
        <Routes> 
            <Route path="/" element={ 
                <Protected >
                    <Dashboard /> 
                </Protected>
            } />
            <Route path="/clients" element={ 
                <Protected >
                    <Clients /> 
                </Protected>
            } />
            <Route path="/companies" element={ 
                <Protected >
                    <Companies /> 
                </Protected>
            } />
            <Route path="/admins" element={ 
                <Protected >
                    <Admin /> 
                </Protected>
            } />
            <Route path="/users" element={ 
                <Protected >
                    <Users /> 
                </Protected>
            } />
            <Route path="/reports" element={ 
                <Protected >
                    <Report /> 
                </Protected>
            } />
            <Route path="/parameters" element={ 
                <Protected >
                    <Parameters /> 
                </Protected>
            } />
            <Route path="/notifications" element={ 
                <Protected >
                    <Notifications /> 
                </Protected>
            } />
            <Route path="login" element={ <Login /> } />
            <Route path="/*" element={ <Navigate to="/"/> } />
        </Routes>
    )
}