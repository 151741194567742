import { UserContext } from '../../context/UserStore';
import { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../assets/img/logo.png'
import Options from '../../options'

function Sidebar () {

    const options = Options.menu;

    const { setIsLoggedIn } = useContext( UserContext );

    const logOut = async () => {
        setIsLoggedIn(false)
        localStorage.setItem("isLoggedIn", false);
        localStorage.setItem("role", "");
        localStorage.setItem("companies", "");
        localStorage.setItem("company", "");
        localStorage.setItem("users-quantity", 0);
        localStorage.setItem("companies-count", 0);
    };

    return (
        <nav id="sidebarMenu" className="d-lg-block">
        <div className="position-sticky">
            <div className='d-flex justify-content-center'>
                <img src={logo} alt="" style={{ maxWidth: '200px'}} />
            </div>
            <div className="list-group list-group-flush mx-3 mt-4">
                {options.map((option, key) => (                    
                    <NavLink to={option.path} key={key} className="list-group-item list-group-item-action py-2 ripple" id={'link'+key}>
                        {option.name}
                    </NavLink>
                ))}
            <Link to='/login' className="list-group-item list-group-item-action py-2 ripple logout" onClick={logOut}>Sign Out</Link>
            </div>
        </div>
        </nav>
    )
}

export default Sidebar;