import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { AppRouter } from './router/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './context/UserStore';
import  StoreProvider  from './context/Store';
import './sass/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <StoreProvider>
    <UserProvider>
      <AppRouter />
    </UserProvider>
  </StoreProvider>
  </BrowserRouter>
);
