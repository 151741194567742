import { useEffect, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from 'react-modal';
import axios from "axios";
import moment from "moment";
import withReactContent from 'sweetalert2-react-content'
import Swal from "sweetalert2";
import IsMobile from "../../services/is-mobile--checker";

const MySwal = withReactContent(Swal)

const customStyles = {
    content: {
        top: '25%',
        left: '25%',
        right: '25%',
        bottom: '25%',
    },
};

Modal.setAppElement('#root');

function Notifications  () {

    const [logo, setLogo] = useState("");
    const company = JSON.parse(localStorage.getItem("company") || "{}");
    const companiesCount = JSON.parse(localStorage.getItem("companies-count") || JSON.stringify([{}]));
    const companiesActives = JSON.parse(localStorage.getItem("actives-companies") || JSON.stringify([{}]));
    const selectedCompanyId = localStorage.getItem("selected-company-id") || '';
    const role = localStorage.getItem("role");
    const [companies, setCompanies] = useState([]);
    const [companyIdToSend, setCompanyIdToSend] = useState();
    const [notifications, setNotifications] = useState();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [canSendNotification, setCanSendNotification] = useState(true);
    const [skip, setSkip] = useState(0);
    const [notificationSkip, setNotificationSkip] = useState(0);
    const [limit] = useState(5);
    const [companiesFetched, setcompaniesFetched] = useState();
    const [idCompanySelected, setIdCompanySelected] = useState();
    const [notificationsFetched, setNotificationsFetched] = useState();
    const [loaded, setLoaded] = useState(false);
    const [pageNumbers, setPageNumbers] = useState([]);
    const [pageNotificationNumbers, setPageNotificationNumbers] = useState([]);
    const [checkboxesArray, setCheckboxesArray] = useState([]);
    const [paginatorNumber, setPaginatorNumber] = useState(15);
    const [paginatorNotificationNumber, setPaginatorNotificationNumber] = useState(15);
    const [selectedPage, setSelectedPage] = useState(1);
    const [selectedNotificationPage, setSelectedNotificationPage] = useState(1);

    const selectAllCheckboxes = () => {
        let checkboxes = document.querySelectorAll('#checkboxForm input[type="checkbox"]');
        let checkboxmaster = document.querySelectorAll('#checkboxMaster input[type="checkbox"]');
    
        for (let i = 0; i < checkboxes.length; i++) {
            if(checkboxmaster[0].checked){
                checkboxes[i].checked = true;
            }else{
                checkboxes[i].checked = false;
            }
        }
    };

    useEffect(() => {
        const isMobile = IsMobile();
        if(isMobile){
          setPaginatorNumber(5);
          setPaginatorNotificationNumber(5);
        }
        setLoaded(true);
    }, []);

    useEffect(() => {
        setLogo(company?.properties?.logo);
    }, [])

    useEffect(() => {
        const fetchNotifications = async () => {
            const {data} = await axios.get(process.env.REACT_APP_BACK_URL + '/notifications' + '?companyId=' + company._id + '&limit=' + 300 + '&skip=' + 0);
            localStorage.setItem('notifications', JSON.stringify(data.notifications))
            setNotifications(JSON.parse(localStorage.getItem("notifications") || JSON.stringify([{}])));
        };
        fetchNotifications();
    }, [])

    useEffect(() => {
        if (company && company.isActive === false) {
            setCanSendNotification(false)
        }
    })



    useEffect(() => {
        if(role === 'superadmin'){
            const fetchNotifications = async () => {
                const {data} = await axios.get(process.env.REACT_APP_BACK_URL + '/notifications' + '?companyId=' + companiesActives[0]?._id + '&limit=' + limit + '&skip=' + 0);
                console.log(data.notifications)
                localStorage.setItem('notifications', JSON.stringify(data.notifications))
                setNotificationsFetched(JSON.parse(localStorage.getItem("notifications") || JSON.stringify([{}])));
                const arrayOfPages = [];
                for (let i = 1; i <= Math.ceil(data.count / limit); i++) {
                    arrayOfPages.push(i);
                }
                setPageNotificationNumbers(arrayOfPages);
            };
            fetchNotifications();
    
            localStorage.setItem('selected-company-id', idCompanySelected)
        }

    }, [idCompanySelected])

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all-actives' + '?limit=' + 300 + '&skip=' + 0);
            localStorage.setItem("companiestotal", JSON.stringify(response.data.companies));
            setCompanies(response.data.companies);
        };
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all-actives' + '?limit=' + limit + '&skip=' + skip);
            localStorage.setItem("companies", JSON.stringify(data.companies));

            const newCompaniesReloadInit = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all-actives' + '?limit=' + limit + '&skip=' + skip);

            setcompaniesFetched(newCompaniesReloadInit.data.companies);
        }

        fetchData();
        const arrayOfPages = [];
        for (let i = 1; i <= Math.ceil(companiesCount / limit); i++) {
            arrayOfPages.push(i);
        }
        setPageNumbers(arrayOfPages);

    }, [skip])

    useEffect(() => {
        const fetchNotificationsData = async () => {
            const companyIdToSend = idCompanySelected ? idCompanySelected : companiesActives[0]?._id
            const { data } = await axios.get(process.env.REACT_APP_BACK_URL + '/notifications' + '?companyId=' + companyIdToSend + '&limit=' + limit + '&skip=' + notificationSkip);
            localStorage.setItem("notifications", JSON.stringify(data?.notifications));

            const newNotificationsReloadInit = await axios.get(process.env.REACT_APP_BACK_URL + '/notifications' + '?companyId=' + companyIdToSend + '&limit=' + limit + '&skip=' + notificationSkip);
            setNotificationsFetched(newNotificationsReloadInit.data.notifications);
            
            const arrayOfPages = [];
            for (let i = 1; i <= Math.ceil(data?.count / limit); i++) {
                arrayOfPages.push(i);
            }
            setPageNotificationNumbers(arrayOfPages);
        }

        fetchNotificationsData();

    }, [notificationSkip])

    const handlePageClick = (pageNumber) => {
        setSelectedPage(pageNumber);
        setSkip(pageNumber - 1);
    };

    const handleNotificationsPageClick = (pageNumber) => {
        setSelectedNotificationPage(pageNumber);
        setNotificationSkip(pageNumber - 1);
    };

    const closeModal = () => {
        setIsOpen(false);
    }

    const handleSubmit = (formData) => {
        const {companyId} = formData;
        setCompanyIdToSend(companyId);
        setIsOpen(true);
    }

    const selectCompany = (event) => {
        setIdCompanySelected(event?.target?.value)
    }
    
    const handleSend = async (formData) => {
        const {message, companyChecked} = formData
        const dataToSend = {
            message, 
            companies: companyChecked, 
            dateTime: new Date(),
        }
        
        await axios.post(process.env.REACT_APP_BACK_URL + '/notifications', dataToSend)

        MySwal.fire({
            title: <strong>Notificación enviada exitosamente!</strong>,
            icon: 'success'
        })

        setIsOpen(false);

        setTimeout(() => {
            window.location.reload();
        }, 2000);

    }

    const [form] = useState({
        companyId: company?.id || companies[0]?.id, 
        message: ""
    });


    return (
        <div className="background-colored">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-2 justify-content-center sidebar">
                    <Sidebar />
                </div>
                <div className="col-12 col-sm-9 justify-content-center datatable-container ml">
                    <div className='header-container mt-3 mb-3'>
                        <span className='header-label'>Notificaciones push</span>
                        {logo && (
                            <img src={logo} style={{ width: '50px', height: 'auto', display: 'flex' }}/>
                        )}
                    </div>
                    <div className="p-2">
                        <Formik
                            initialValues={{ companyId: form.companyId, date: form.dates }}
                            // validate={(values) => {
                            // const errors = {};
                            // if (!values.companyId) {
                            //     errors.company = 'Seleccione la empresa';
                            // } 
                            // return errors;
                            // }}
                            onSubmit={handleSubmit}
                        >
                            <Form className="row">
                                <div className="col-sm-2">
                                    <Field as="select" id="companyId" name="companyId" value={selectedCompanyId} className='form-select fs-empresa' onChange={selectCompany}>
                                    {role === 'superadmin' ? (
                                        companies.map((company, key) => (
                                            <option key={key} value={company._id} >{company.name}</option>
                                        ))

                                    ) : (
                                        <option value={company._id}>{company.name}</option>
                                    )}
                                    </Field> 
                                </div>
                                
                                <div className="col-sm-3">
                                    {canSendNotification &&  (
                                        <button type="submit" className='btn btn-primary-custom m-0 pl-4 pr-4 pt-2 pb-2'>Enviar Notificación</button>                  
                                    )}
                                </div>
                                {notificationsFetched && (
                                    <div className='p-4 mt-4 table-container'>
                                    <table className="table table-striped text-center">
                                        <thead>
                                                <tr >
                                                    <th scope="col" className="th w-25 bold">Mensaje</th>
                                                    <th scope="col" className="th w-25">Fecha</th>
                                                </tr>

                                        </thead>
                                        <tbody>
                                            {notificationsFetched.map((notification, key) => (  
                                                <tr key={key}>
                                                    <td scope="row">{notification?.message}</td>
                                                    <td>{moment(notification?.dateWithFormat).utcOffset('+00:00').format('DD/MM/YYYY')}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    </div>
                                )}
                            </Form>
                        </Formik>
                        <div className="row mt-4">
                            <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                                {pageNotificationNumbers && pageNotificationNumbers.length > 0 &&
                                    <li className="page-item"><button type="submit" className={`page-link ${notificationSkip > 0 ? '' : 'disabled'}`} 
                                        onClick={() => handleNotificationsPageClick(selectedNotificationPage - 1)}>{'<<'}</button></li> 
                                    }
                                    {pageNotificationNumbers.map((pageNotificationNumber, index) => (
                                    <li className="page-item" key={pageNotificationNumber}><button type="submit" 
                                        className={`page-link numbers ${pageNotificationNumber <= paginatorNotificationNumber ? 'show' : 'hidden'} ${selectedNotificationPage === 1 && index === 0 && loaded ? 'first-page' : ''} ${pageNotificationNumber === selectedNotificationPage ? 'selected-page' : ''}`}
                                        onClick={() => handleNotificationsPageClick(pageNotificationNumber)} >{pageNotificationNumber}</button></li>
                                    ))}
                                    {pageNotificationNumbers && pageNotificationNumbers.length > 0 &&
                                    <li className="page-item"><button type="submit" className={`page-link ${notificationSkip < pageNotificationNumbers.length - 1 ? '' : 'disabled'}`} 
                                        onClick={() => handleNotificationsPageClick(selectedNotificationPage + 1)}>{'>>'}</button></li> 
                                }
                            </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Crear nueva empresa"
            >
            <div role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">Notificaciones Push</h5>
                    
                    </div>
                    <hr className="disapearMobil"/>
                    <div className="modal-body">
                    <div className="container-fluid">

                    <Formik
                        initialValues={{ message: '', companyChecked: '' }} 
                        validate={(values) => {
                        const errors = {};
                        if (!values.message) {
                            errors.message = 'Campo mensaje es requerido';
                        } 
                        if (!values.companyChecked) {
                            errors.companyChecked = 'Debe seleccionar al menos una empresa';
                        } 
                        return errors;
                        }}
                        onSubmit={handleSend}
                    >
                        <Form>
                        <div className="row">
                            <div className="mb-3 form-group col-12">
                                <label htmlFor="message">Mensaje <sup>*</sup> </label>
                                <Field type="text" name="message" id="message" className='form-control' placeholder="Ingresa mensaje"  maxLength={300} />
                                <ErrorMessage name="message" component="div" />
                            </div>
                        </div>
                        <div className="row">
                            <div className='p-4 mt-4 table-container'>
                                <table className="table table-striped text-center">
                                    <thead>
                                            <tr>
                                                <th scope="col" className="th w-25 bold">Empresa</th>
                                                <th scope="col" className="th w-25" id="checkboxMaster"> 
                                                        Selección 
                                                        {role === 'superadmin' && (
                                                                <>
                                                                <label className="mx-2">
                                                                    <span>
                                                                        <i className="bi bi-check-all bigger"></i>  
                                                                    </span>
                                                                    <input type="checkbox" onChange={selectAllCheckboxes}>
                                                                    </input>
                                                                </label>
                                                                </>
                                                        )}
                                                </th>
                                            </tr>

                                    </thead>
                                    <tbody >
                                        {role === 'superadmin' && companiesFetched ? (
                                            companiesFetched.map((company, key) => (
                                                <tr key={key} id="checkboxForm">
                                                    <td scope="row"  >{company.name}</td>
                                                    <td><Field type="checkbox" name="companyChecked" value={company._id} checked={checkboxesArray[key]} /></td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td value={company._id} >{company.name}</td>
                                                <td><Field type="checkbox" name="companyChecked" value={company._id} /></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="form-control d-none"></div><ErrorMessage name="companyChecked" component="div" />
                                <div className="row mt-4">
                                    <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        {pageNumbers && pageNumbers.length > 0 &&
                                        <li className="page-item"><button type="submit" className={`page-link ${skip > 0 ? '' : 'disabled'}`} 
                                        onClick={() => handlePageClick(selectedPage - 1)}>{'<<'}</button></li> /* onClick={() => setSkip(skip - 1)} */
                                        }
                                        {pageNumbers.map((pageNumber, index) => (
                                        <li className="page-item" key={pageNumber}><button type="submit" 
                                        className={`page-link numbers ${pageNumber <= paginatorNumber ? 'show' : 'hidden'} ${selectedPage === 1 && index === 0 && loaded ? 'first-page' : ''} ${pageNumber === selectedPage ? 'selected-page' : ''}`}
                                        
                                        onClick={() => handlePageClick(pageNumber)} >{pageNumber}</button></li>/*  className={"page-link numbers " + (pageNumber <= 25 ? 'show' : 'hidden')} */
                                        ))}
                                        {pageNumbers && pageNumbers.length > 0 &&
                                        <li className="page-item"><button type="submit" className={`page-link ${skip < pageNumbers.length - 1 ? '' : 'disabled'}`} 
                                        onClick={() => handlePageClick(selectedPage + 1)}>{'>>'}</button></li> /* onClick={() => setSkip(skip + 1)} */
                                        }
                                    </ul>
                                    </nav>
                                </div>
                            </div>
                            <hr className="disapearMobil"/>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary-custom" onClick={closeModal}>Regresar</button>
                                <button type="submit" className="btn btn-primary-custom">Enviar</button>
                            </div>
                        </Form>
                    </Formik>
                    </div>
                    </div>
                </div>
            </div>
        </Modal>
        </div>
    );
}

export default Notifications;