import { Field, Formik, Form } from "formik";
import Sidebar from "../sidebar/Sidebar";
import { useState,useEffect } from "react";
import Months from "../../months";
import Years from "../../years";
import axios from "axios";
import moment from 'moment'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import React from 'react';
import IsMobile from "../../services/is-mobile--checker";


const handleDownloadExcel = async () => {
  const monthS = document.getElementById('month').value;
  const yearS = document.getElementById('year').value;
  const companyIdS  = document.getElementById('companyId').value;
 
  const dataToSend = {
    companyId: companyIdS,
    year:parseInt(yearS),
    month: parseInt(monthS), 
    limit: 500000, 
    skip: 0
  }

 var dataforexcel;
  try {
    dataforexcel = await axios.post(process.env.REACT_APP_BACK_URL + '/vote/votes-by-company-id', dataToSend)
 
    
    
  } catch (err) {
    console.log(err.response.data)
  }



  try {
    const response = await axios.get(process.env.REACT_APP_BACK_URL + '/reports/download', {
      responseType: 'arraybuffer', // Indicamos que la respuesta es un array de bytes
    });

    // Crear un nuevo libro de Excel
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Reporte');

    // Obtener los datos del objeto
    const data = dataforexcel.data.results

    // Crear las columnas en la hoja de cálculo
    worksheet.columns = [
   
      { header: 'Nombre', key: 'user.name', headerStyle: { font: { bold: true } } },
      { header: 'Fecha', key: 'user.fecha', headerStyle: { font: { bold: true } } },
      { header: 'Reacción', key: 'user.state', headerStyle: { font: { bold: true } } },
      { header: 'Motivo', key: 'user.motivo' },
      { header: 'Cargo', key: 'user.cargo' },
      { header: 'Área', key: 'user.area' },
      { header: 'Ciudad', key: 'user.ciudad' },
      { header: 'País', key: 'user.pais' },
      { header: 'Fecha Nacimiento', key: 'user.nacimiento' },
      { header: 'Género', key: 'user.genero' },
      { header: 'Teléfono', key: 'user.phone' },
     
      // Agrega las demás columnas que desees mostrar
    ];

    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell(function (cell) {
        if (rowNumber === 1) {
          cell.font = { bold: true };
        }
      });
    });
    // Agregar los datos a la hoja de cálculo
    data.forEach((item) => {
      worksheet.addRow({
      /*   _id: item._id,
        'user._id': item.user._id,
        'user.identification': item.user.identification,
        
        'user.lastname': item.user.lastname,
        'user.email': item.user.email, */
        'user.name': item?.user?.name +' '+ item?.user?.lastname,
        'user.fecha': moment(item?.dateTime).utc(moment.ISO_8601).format('YYYY-MM-DD hh:mm a'),
        'user.state': item?.state,
        'user.motivo': item?.description,
        'user.cargo': item?.user?.cargo,
        'user.area': item?.user?.area,
        'user.ciudad': item?.user?.ciudad,
        'user.pais': item?.user?.pais,
        'user.nacimiento': moment(item?.user?.fechanacimiento).utc().format('L'),
        'user.genero': item?.user?.genero,
        'user.phone': item?.user?.phone,
       
        // Agrega los demás campos que desees mostrar
      });
    });
    // Convertir el libro de Excel a un blob
    const buffer = await workbook.xlsx.writeBuffer();

    // Crear el blob
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Generar el enlace de descarga
    const url = URL.createObjectURL(blob);

    // Descargar el archivo
    const link = document.createElement('a');
    link.href = url;
    link.download = 'reporte.xlsx';
    link.click();
  } catch (error) {
    console.error('Error al descargar el archivo de Excel', error);
  }
};


function Report () {
  
  const [loaded, setLoaded] = useState(false);
  const [paginatorNumber, setPaginatorNumber] = useState(15);

  useEffect(() => {
    const isMobile = IsMobile();
    if(isMobile){
      setPaginatorNumber(5);
    }
    setLoaded(true);
  }, []);

  const [yearsToShow, setYearsToShow] = useState([]);

  useEffect(() => {
    const getyearsToShow = () => {
      const date = new Date();
      const year = date.getFullYear();
      const ArrayOfyearsToShow = [year-2, year-1, year];
      setYearsToShow(ArrayOfyearsToShow);
      console.log(year)
    };

    getyearsToShow();
  }, []); 

  const [selectedPage, setSelectedPage] = useState(1);
  const [dataTotal, setdataTotal] = useState([]);
  //var dataTotal = JSON.parse(localStorage.getItem("companiestotal") || JSON.stringify([{}]));;
    const [statusDownExc, setStatusDownExc] = useState(true);
    const companies = JSON.parse(localStorage.getItem("companies") || JSON.stringify([{}]));
    const company = JSON.parse(localStorage.getItem("company") || JSON.stringify({}));
    const  role = localStorage.getItem("role");
    const [reportsData, setReportsData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [limit] = useState(5);
    const [pageNumbers, setPageNumbers] = useState([]);
    const [noInfo, setNoInfo] = useState(false);

    const [form] = useState({
        companyId: companies[0]?._id, month: 1, year: 2023
    });
    const handlePageClick = (pageNumber) => {
      setSelectedPage(pageNumber);
      setSkip(pageNumber - 1);
    };

    useEffect(() => {
      const fetchData = async () => {
     
      const response = await axios.get(process.env.REACT_APP_BACK_URL + '/company/all' + '?limit=' + 300 + '&skip=' + 0);
      localStorage.setItem("companiestotal", JSON.stringify(response.data.companies));
      setdataTotal(response.data.companies);// dataTotal=response.data.companies;
      //setComapniesList(response);
    };
    fetchData();
    }, [])
    const handleSubmit = async (formData) => {
      const {companyId, month, year} = formData
      const dataToSend = {
        companyId: role === 'superadmin' ? companyId : company._id, 
        month: parseInt(month), 
        year: parseInt(year), 
        limit: limit, 
        skip: skip
      }

      try {
        const {data} = await axios.post(process.env.REACT_APP_BACK_URL + '/vote/votes-by-company-id', dataToSend)
        setReportsData(data.results)
        if (data.count>0){
          setNoInfo(false)
          setStatusDownExc(false);
        }else{
          setNoInfo(true)
          setStatusDownExc(true);
        }
        const arrayOfPages = [];
         
        for (let i = 1; i <= Math.ceil(data.count / limit); i++) {
          arrayOfPages.push(i);
        }

        setPageNumbers(arrayOfPages);
        
      } catch (err) {
        console.log(err.response.data)
      }
    }

    return (
        <div className="background-colored">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-2 justify-content-center sidebar">
            <Sidebar />
          </div>
          <div className="col-12 col-sm-9 justify-content-center datatable-container ml">
            <div className='header-container'>
              <span className='header-label'>Generación de Reporte</span>
              <button className=' btn btn-primary-custom' onClick={() => handleDownloadExcel()} disabled={statusDownExc}>Descargar Excel  <i className="bi bi-file-earmark-excel"></i></button>
            </div>
            <div className="p-2">
                <Formik
                  initialValues={{ companyId: form.companyId, month: form.month, year: form.year }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.companyId) {
                      errors.company = 'Seleccione la empresa';
                    } 

                    if (!values.month) {
                      errors.month = 'Seleccione el mes';
                    } 
                    return errors;
                  }}
                  onSubmit={handleSubmit}
                >
                <Form className="row">
                    <div className="col-sm-2">
                        <Field as="select" id="companyId" name="companyId" className='form-select fs-empresa' >
                          {role === 'superadmin' ? (
                            dataTotal.map((company, key) => (
                              <option value={company._id} key={key} >{company.name}</option>
                            ))

                          ) : (
                            <option value={company._id}>{company.name}</option>
                          )}
                        </Field> 
                    </div>
                    <div className="col-sm-2">
                        <Field as="select" id="month" name="month" className='form-select fs-empresa'>
                            {Months.map((month, key) => (
                              <option value={month.value} key={key} >{month.name}</option>
                            ))}
                        </Field> 
                    </div>
                    <div className="col-sm-2">
                        <Field as="select" id="year" name="year" className='form-select fs-empresa'>
                            {yearsToShow.map((year, key) => (
                              <option value={year} key={key} >{year}</option>
                            ))}
                        </Field> 
                    </div>
                    <div className="col-sm-3">
                      <button className='btn btn-secondary-dark m-0 pl-4 pr-4 pt-2 pb-2'>Filtrar</button>                  
                    </div>

                    {reportsData.length > 0 && (
                      <div className='container mt-4'>
                        <div className=' mt-4 mb-4 scroll-container'>
                          <table className="table table-striped table-sm text-center table-responsive">
                            <thead>
                              <tr>
                                <th scope="col" className="th table-report-th">Fecha</th>
                                <th scope="col" className="th table-report-th">Nombres y Apellidos</th>
                                <th scope="col" className="th table-report-th">Reacción</th>
                                <th scope="col" className="th table-report-th">Motivo</th>
                                <th scope="col" className="th table-report-th">Cargo</th>
                                <th scope="col" className="th table-report-th">Área</th>
                                <th scope="col" className="th table-report-th">Ciudad</th>
                                <th scope="col" className="th table-report-th">País</th>
                                <th scope="col" className="th table-report-th">Fecha de nacimiento</th>
                                <th scope="col" className="th table-report-th">Género</th>
                                <th scope="col" className="th table-report-th">Teléfono</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportsData.map((item, key) => (
                                <tr key={key}>
                                  <td scope="row">{moment(item?.dateTime).utc(moment.ISO_8601).format('YYYY-MM-DD hh:mm a')}</td>
                                  <td>{item?.user?.name} {item?.user?.lastname}</td>
                                  <td>{item?.state}</td>
                                  <td>{item?.description}</td>
                                  <td>{item?.user?.cargo}</td>
                                  <td>{item?.user?.area}</td>
                                  <td>{item?.user?.ciudad}</td>
                                  <td>{item?.user?.pais}</td>
                                  <td>{moment(item?.user?.fechanacimiento).utc().format('L')}</td>
                                  <td>{item?.user?.genero}</td>
                                  <td>{item?.user?.phone}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="row ">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                              { pageNumbers.length > 0 && 
                                <li className="page-item"><button type="submit" className={`page-link ${skip > 0 ? '' : 'disabled'}`} 
                                onClick={() => handlePageClick(selectedPage - 1)}>{'<<'}</button></li> /* onClick={() => setSkip(skip-1)} */
                              }
                              {pageNumbers.map((pageNumber, index) => (
                                <li className="page-item" key={pageNumber}><button type="submit" 
                                className={`page-link numbers ${pageNumber <= paginatorNumber ? 'show' : 'hidden'}${selectedPage === 1 && index === 0 && loaded ? 'first-page' : ''} ${pageNumber === selectedPage ? 'selected-page' : ''}`}
                                onClick={() => handlePageClick(pageNumber)} >{pageNumber}</button></li>
                              ))}
                              { pageNumbers.length > 0 && 
                                <li className="page-item"><button type="submit" className={`page-link ${skip < pageNumbers.length -1 ? '' : 'disabled'}`}
                                onClick={() => handlePageClick(selectedPage + 1)}>{'>>'}</button></li> /*  onClick={() => setSkip(skip+1)} */
                              }
                            </ul>
                          </nav>
                        </div>
                      </div>
                    )}

                    {noInfo && (
                      <div className="container mt-4">
                        <div className="row">
                          <div className="col-12">
                            <div className="alert alert-light" role="alert">
                              No se han encontrado registros
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    </Form>
                  </Formik>
                </div>
          </div>
        </div>
      </div>
    )
}

export default Report;