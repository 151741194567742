const Options = {
    menu : [
        {
            name: 'Dashboard', 
            path: '/',
            isAvailable: true 
        },
        {
            name: 'Clientes y Usuarios', 
            path: '/clients',
            isAvailable: true 
        },
        {
            name: 'Administradores', 
            path: '/companies',
            isAvailable: true 
        },
        {
            name: 'Reportes', 
            path: '/reports',
            isAvailable: true 
        },
        {
            name: 'Parámetros', 
            path: '/parameters',
            isAvailable: true 
        },
        {
            name: 'Notificaciones', 
            path: '/notifications',
            isAvailable: true 
        },
    ], 
    charts: {   
        emotions: {
            generalLabel: ' Valoraciones', 
            backgroundColor: ['#CB3234', '#C7C8CA', '#24da1e', '#FF8000']
        }
    }, 
    gauge: {   
        colors: ['#CB3234', '#C7C8CA', '#24da1e', '#FF8000']
    }, 
    dates: [
        {
            label: "Último día",
            value: "last-day"
        },
        {
            label: "Última semana",
            value: "last-week"
        },
        {
            label: "Último mes",
            value: "last-month"
        },
        {
            label: "Último año",
            value: "last-year"
        },
        {
            label: "Total",
            value: "total"
        },
    ],
    radios: [
        {
            label: "Fecha",
            value: "date"
        },
        {
            label: "Ubicación",
            value: "country-city"
        },
    ]
};

export default Options;