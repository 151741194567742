import AWS from 'aws-sdk';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;


const UploadS3 = (file) => {
    return new Promise((resolve, reject) => {
        const s3 = new AWS.S3({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
            region: REGION,
        });

        const params = {
            Bucket: S3_BUCKET+'/app_mobil/imagenesEmpresas',
            Key: file.name,
            Body: file,
            ACL: 'public-read', 
        };
        s3.upload(params, (err, data) => {
            if (err) {
                console.log(err);
                reject(err)
            }else{ 
                resolve(data.Location);
            }
        }); 
    });
};


export default UploadS3;
