import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import Sidebar from "../sidebar/Sidebar";
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Formik, Form } from 'formik';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';

const MySwal = withReactContent(Swal)
const customStyles = {
  content: {
    top: '25%',
    left: '25%',
    right: '25%',
    bottom: '25%',
  },

};

function Admin() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);
  const usersData = JSON.parse(localStorage.getItem("admins") || JSON.stringify({}));
 
  const companyId = localStorage.getItem("companyId");
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState(1);
  const [modalIsOpenAdmin, setIsOpenAdmin] = useState(false);
  const [modalIsOpenUpdate, setIsOpenUpdate] = useState(false);
  const [dataToUpdate, setdataToUpdate] = useState();
  const [usersDataFetched, setusersDataFetched] = useState(usersData.users);
  const [skip, setSkip] = useState(0);
  const [limit] = useState(5);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [noInfo, setNoInfo] = useState(false);
  const [companiesResponse, setcompaniesResponse] = useState();

  useEffect(() => {
    const usersSidebar =  document.querySelector('#link2');
    usersSidebar.classList.add('customized');
    
  }, []);
 
  useEffect(() => {

    const fetchData = async () => {
      const { data } = await axios.get(process.env.REACT_APP_BACK_URL + '/admin/getUserBycompanyId?companyId=' + companyId + '&limit=' + limit + '&skip=' + skip);
     
      localStorage.setItem("admins-fetched", JSON.stringify(data.users));
      const companiesResponse = await axios.get(process.env.REACT_APP_BACK_URL + '/company/search/' + companyId);
      const { nombreCompany } = await axios.get(process.env.REACT_APP_BACK_URL + '/company/search/' + companyId );
      setcompaniesResponse(companiesResponse.data.name);

      const newUsersReloadInit = await axios.get(process.env.REACT_APP_BACK_URL + '/admin/getUserBycompanyId?companyId=' + companyId + '&limit=' + limit + '&skip=' + skip)
    
      setusersDataFetched(newUsersReloadInit.data.users);

    }

    fetchData();

    const arrayOfPages = [];

    for (let i = 1; i <= Math.ceil(usersData.count / limit); i++) {
      arrayOfPages.push(i);
    }

    setPageNumbers(arrayOfPages);

    if (usersDataFetched && usersDataFetched.length == 0)
      setNoInfo(true)

  }, [skip])


  const [adminForm] = useState(
      {
      username:"", 
      password:"",
      roles: "admin",
      isActive:true,
      companyId:""
  });


  const handleSubmitAdmin = async (formData) => {

    const {companyId, ...data} = formData

    const dataToSend = {
        username: data.username, 
        password: data.password, 
        isActive: data.isActive,
        roles: ['admin'],
        companyId: companyId
    }


    try {
      await axios.post(process.env.REACT_APP_BACK_URL + '/admin/create', dataToSend)

      MySwal.fire({
        title: <strong>El usuario {dataToSend.username} fue creado exitosamente!</strong>,
        icon: 'success'
      })
      setIsOpenAdmin(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      
    } catch (err) {
      console.log(err.response.data)
    }
  };

  const handleUpdate = async (formData) => {

    const {
      username,
      password,
      isActive, 
      _id
    } = formData

  const isActiveBoolean = isActive === "activo" ? true : false

    const dataToSend = {
      username: username,
      password: password,
      isActive: isActiveBoolean,
    }

    try {
      const updateUser = await axios.patch(process.env.REACT_APP_BACK_URL + '/admin/update/' + _id, dataToSend)

      if(updateUser?.data?.existIn){
        MySwal.fire({
          title: <strong>Usuario {formData.username} actualmente ya se encuentra registrado y activo</strong>,
          icon: 'error',
        })
      } else {
        MySwal.fire({
          title: <strong>El usuario {formData.username} ha sido actualizado exitosamente!</strong>,
          icon: 'success',
        })


        const newUsersReloadInit = await axios.get(process.env.REACT_APP_BACK_URL + '/admin/getUserBycompanyId?companyId=' + companyId + '&limit=' + limit + '&skip=' + skip)
      
        
        setTimeout(() => {
          setusersDataFetched(newUsersReloadInit.data.users);
          window.location.reload()
        }, 1000);


        setIsOpenUpdate(false);
      }
    } catch (err) {
      console.log(err.response.data)
    }
  };


  const goBack = () => {
    navigate(-1);
  };

  const openAdminModal = () => {
    setIsOpenAdmin(true);
  };

  const closeAdminModal = () => {
    setIsOpenAdmin(false);
  };

  const closeModalUpdate = () => {
    setIsOpenUpdate(false);
  };

  const editUser = (item) => {
    setdataToUpdate(item)
    setIsOpenUpdate(true);
  }
  const handlePageClick = (pageNumber) => {
    setSelectedPage(pageNumber);
    setSkip(pageNumber - 1);
  };

  return (
    <div className="background-colored">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-2 justify-content-center sidebar">
          <Sidebar />
        </div>
        <div className="col-12 col-sm-9 justify-content-center datatable-container ml">
          <div className="header-container">
            <div>
              <a className="arrow" onClick={goBack}>
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
              </a>
              <span id="customized" className="header-label">{companiesResponse} / Usuarios </span>
            </div>
            <div className="dropdown">
              <button className="btn btn-primary-custom" type="button" onClick={openAdminModal}>
                Nuevo
              </button>
            </div>
          </div>
          {usersDataFetched && usersDataFetched.length > 0 && (
            <div className="mt-4 scroll-container">
              <table className="table table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col" className="th">Username</th>
                    <th scope="col" className="th">Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {usersDataFetched.map((item, key) => (
                    <tr key={key}>
                      <td scope="row" className="users"><a onClick={() => editUser(item)}>{item?.username}</a></td>
                      <td>{item?.isActive ? 'Activo' : 'Inactivo'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {noInfo && usersDataFetched.length == 0 && (
            <div className="container mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="alert alert-light" role="alert">
                    No se han encontrado registros
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row mt-4">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                {pageNumbers && pageNumbers.length > 0 &&
                  <li className="page-item"><button type="submit" className={`page-link ${skip > 0 ? '' : 'disabled'}`} onClick={() => handlePageClick(selectedPage - 1)} >{'<<'}</button></li> /* onClick={() => setSkip(skip - 1)} */
                }
                {pageNumbers.map((pageNumber, index) => (
                  <li className="page-item" key={pageNumber}><button type="submit" 
                  className={`page-link numbers ${pageNumber <= 15 ? 'show' : 'hidden'} ${selectedPage === 1 && index === 0 && loaded ? 'first-page' : ''} ${pageNumber === selectedPage ? 'selected-page' : ''}`}
                  onClick={() => handlePageClick(pageNumber)}  >{pageNumber}</button></li> /*  onClick={() => setSkip(pageNumber - 1)} */
                ))}
                {pageNumbers && pageNumbers.length > 0 &&
                  <li className="page-item"><button type="submit" className={`page-link ${skip < pageNumbers.length - 1 ? '' : 'disabled'}`}  onClick={() => handlePageClick(selectedPage + 1)}>{'>>'}</button></li>/* onClick={() => setSkip(skip + 1)} */
                }
              </ul>
            </nav>
          </div>


          {/* Admin modal */}
          <Modal
            isOpen={modalIsOpenAdmin}
            onRequestClose={closeAdminModal}
            style={customStyles}
            contentLabel="Crear Admin"
          >
            <div role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Nuevo
                  </h5>
                </div>
                <hr className="disapearMobil"/>
                <div className="modal-body">
                  <div className="container-fluid">
                    <Formik
                      initialValues={{
                        username: adminForm.username,
                        password: adminForm.password,
                        roles: adminForm.roles,
                        isActive: true,
                        companyId: companyId
                      }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.username) {
                          errors.username = "Campo nombre de usuario es requerido";
                        }

                        if (!values.password) {
                          errors.password = "Campo contraseña es requerido";
                        }

                        return errors;
                      }}
                      onSubmit={handleSubmitAdmin}
                    >
                      <Form>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="username">
                              Nombre de Usuario <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="username"
                              id="username"
                              className="form-control"
                              placeholder="Ingresa nombre de usuario"
                              maxLength={60}
                            />
                            <ErrorMessage name="username" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="password">
                            Password <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="password"
                              name="password"
                              id="password"
                              className="form-control"
                              placeholder="Ingresa password"
                              maxLength={15}
                            />
                            <ErrorMessage name="password" component="div" />
                          </div>
                        </div>

                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <Field type="hidden" name="companyId" id="companyId" className='form-control' value={dataToUpdate?._id} />
                          </div>
                        </div>
                        <hr className="disapearMobil" />
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary-custom"
                            onClick={closeAdminModal}
                          >
                            Regresar
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary-custom"
                          >
                            Guardar
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Modal>


          {/* Edit modal */}
          <Modal
            isOpen={modalIsOpenUpdate}
            onRequestClose={closeModalUpdate}
            style={customStyles}
            contentLabel="Actualizar Administrador"
          >
            <div role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    { dataToUpdate?.username }
                  </h5>
                </div>
                <hr className="disapearMobil" />
                <div className="modal-body">
                  <div className="container-fluid">
                    <Formik
                    
                      initialValues={{
                        username: dataToUpdate?.username,
                        isActive: dataToUpdate?.isActive ? "activo" : "inactivo",
                        password: dataToUpdate?.password, 
                        _id: dataToUpdate?._id,
                      }}
                      onSubmit={handleUpdate}
                    >
                      <Form>
                      <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="username">
                              Nombre de Usuario <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="username"
                              id="username"
                              className="form-control"
                              placeholder="Ingresa nombre de usuario"
                              maxLength={60}
                            />
                            <ErrorMessage name="username" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="password">
                            Password <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="password"
                              name="password"
                              id="password"
                              className="form-control"
                              placeholder="Ingresa password"
                              maxLength={15}
                            />
                            <ErrorMessage name="password" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <Field type="hidden" name="companyId" id="companyId" className='form-control' value={dataToUpdate?._id} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="isActive">
                              Estado {" "}
                            </label>
                            <Field as="select" name="isActive" className='form-control select'>
                              <option value="activo">Activo</option>
                              <option value="inactivo">Inactivo</option>
                            </Field>
                          </div>
                        </div>
                        <div className="mb-3 form-group col-6">
                          <Field type="hidden" name="_id" id="_id" className='form-control' value={dataToUpdate?._id} />
                        </div>
                        <hr className="disapearMobil" />
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary-custom"
                            onClick={closeModalUpdate}
                          >
                            Regresar
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary-custom"
                          >
                            Guardar
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

        </div>
      </div>
    </div>
  );
}
export default Admin;
