import { createContext } from "react";
import { useState } from "react"

export const StoreContext = createContext();

export default ({children}) => {

    const [company, setCompany] = useState([])

    const [companies, setCompanies] = useState([])
    const [companiestotal, setCompaniesTotal] = useState([])
    const [users, setUsers] = useState([])
    const [role, setRole] = useState([])

    const store = {
        company: [company, setCompany], 
        companies: [companies, setCompanies], 
        companiestotal: [companiestotal, setCompaniesTotal], 
        users: [users, setUsers], 
        role: [role, setRole], 
    }

    return (
        <StoreContext.Provider value={store}>
            { children }
        </StoreContext.Provider>
    )
}