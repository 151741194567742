import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import Sidebar from "../sidebar/Sidebar";
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Formik, Form } from "formik";
import Swal from "sweetalert2";
import { StoreContext } from "../../context/Store";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import template from "../../template.csv";
import moment from "moment";
import ExcelJS from "exceljs";
import * as Yup from "yup";

const MySwal = withReactContent(Swal);
const customStyles = {
  content: {
    top: "25%",
    left: "25%",
    right: "25%",
    bottom: "25%",
  },
};

function Users() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);
  const usersData = JSON.parse(
    localStorage.getItem("users") || JSON.stringify({})
  );

  const companyId = localStorage.getItem("companyId");
  const navigate = useNavigate();
  const [newIdentification, setNewIdentification] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const [newArea, setNewArea] = useState("");
  const role = localStorage.getItem("role");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAdmin, setIsOpenAdmin] = useState(false);
  const [modalIsOpenUpdate, setIsOpenUpdate] = useState(false);
  const [modalIsOpenUpload, setIsOpenUpload] = useState(false);
  const [dataToUpdate, setdataToUpdate] = useState();
  const [csvData, setCsvData] = useState();
  const [usersDataFetched, setusersDataFetched] = useState(usersData.users);
  const [skip, setSkip] = useState(0);
  const [limit] = useState(5);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [noInfo, setNoInfo] = useState(false);
  const [companiesResponse, setcompaniesResponse] = useState();
  const [hoyDia, setHoyDia] = useState();
  const [showInactives, setShowInactives] = useState(false);

  useEffect(() => {
    const usersSidebar = document.querySelector("#link0");
    usersSidebar.classList.add("customized");
    /* const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    setHoyDia(formattedDate); */

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setHoyDia(formattedDate);
  }, []);

  useEffect(() => {
    if (showInactives) {
      fetchInactiveUsers();
    } else {
      fetchActiveUsers();
    }
  }, [skip]);

  const [form] = useState({
    identification: "",
    name: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    genero: "masculino",
    cargo: "",
    pais: "",
    ciudad: "",
    area: "",
    phone: "",
    fechanacimiento: "",
    isActive: true,
    companyId: "",
  });

  const [adminForm] = useState({
    username: "",
    password: "",
    roles: "admin",
    isActive: true,
    companyId: "",
  });

  const validationform = async (value) => {
    //form

    switch (value.name) {
      case "area":
        setNewArea(value.value);
        break;
      case "identification":
        setNewIdentification(value.value);
        break;
      case "newEmail":
        setNewEmail(value.value);
        break;
      default:
        break;
    }
  };

  const validationSchema = Yup.object({
    // ...
    email: Yup.string()
      .email("Formato de email inválido")
      .required("Campo email es requerido"),
    // ...
  });

  const handleSubmit = async (formData) => {
    const {
      identification,
      name,
      lastname,
      email,
      password,
      genero,
      cargo,
      pais,
      ciudad,
      area,
      phone,
      fechanacimiento,
      isActive,
      companyId,
    } = formData;

    const dataToSend = {
      identification: identification,
      name: name,
      lastname: lastname,
      email: email,
      password: password,
      phone: phone,
      genero: genero,
      cargo: cargo,
      fechanacimiento: new Date(fechanacimiento),
      pais: pais,
      ciudad: ciudad,
      area: area,
      isActive: isActive,
      companyId: companyId,
    };

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const creationUser = await axios.post(
        process.env.REACT_APP_BACK_URL + "/auth/create",
        dataToSend
      );

      if (creationUser?.data?.existIn) {
        MySwal.fire({
          title: (
            <strong>
              El usuario {dataToSend.name} ya se encuentra registrado!
            </strong>
          ),
          icon: "info",
        });
      } else {
        MySwal.fire({
          title: (
            <strong>
              El usuario {dataToSend.name} fue creado exitosamente!
            </strong>
          ),
          icon: "success",
        });
        const { data } = await axios.get(
          process.env.REACT_APP_BACK_URL +
            "/auth/getAllActiveUsers?companyId=" +
            companyId +
            "&limit=" +
            limit +
            "&skip=" +
            skip
        );

        localStorage.setItem("users", JSON.stringify(data));
        //setusersDataFetched(newUsersReload.data.users);

        /*   const newUsersReload = await axios.get(process.env.REACT_APP_BACK_URL + '/auth/getAllActiveUsers?companyId=' + companyId + '&limit=' + limit + '&skip=' + skip)
         */
        setIsOpen(false);
        window.location.reload();
      }
    } catch (err) {
      console.log(err.response.data);
    }
  };

  const handleSubmitAdmin = async (formData) => {
    const { roles, companyId, ...data } = formData;

    let dataToSend = {};

    if (roles == "superadmin") {
      dataToSend = {
        username: data.username,
        password: data.password,
        isActive: data.isActive,
        roles: [roles],
      };
    } else {
      dataToSend = {
        username: data.username,
        password: data.password,
        isActive: data.isActive,
        roles: [roles],
        companyId: companyId,
      };
    }

    try {
      await axios.post(
        process.env.REACT_APP_BACK_URL + "/admin/create",
        dataToSend
      );

      MySwal.fire({
        title: (
          <strong>
            El usuario {dataToSend.username} fue creado exitosamente!
          </strong>
        ),
        icon: "success",
      });
      setIsOpenAdmin(false);
    } catch (err) {
      console.log(err.response.data);
    }
  };

  const handleUpdate = async (formData) => {
    const {
      identification,
      name,
      lastname,
      email,
      password,
      genero,
      cargo,
      pais,
      ciudad,
      area,
      phone,
      fechanacimiento,
      isActive,
      companyId,
    } = formData;

    const isActiveBoolean = isActive === "activo" ? true : false;

    const dataToSend = {
      identification: identification,
      name: name,
      lastname: lastname,
      email: email,
      password: password,
      phone: phone,
      genero: genero,
      cargo: cargo,
      fechanacimiento: moment(fechanacimiento).toDate(),
      pais: pais,
      ciudad: ciudad,
      area: area,
      isActive: isActiveBoolean,
      companyId: companyId,
    };

    const id = formData._id;
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const updateUser = await axios.patch(
        process.env.REACT_APP_BACK_URL + "/auth/update/" + id,
        dataToSend
      );

      if (updateUser?.data?.existIn) {
        MySwal.fire({
          title: (
            <strong>
              Usuario {formData.email} actualmente ya se encuentra registrado y
              activo
            </strong>
          ),
          icon: "error",
        });
      } else {
        MySwal.fire({
          title: (
            <strong>
              El usuario {formData.name} ha sido actualizado exitosamente!
            </strong>
          ),
          icon: "success",
        });

        const newUsersReloadInit = await axios.get(
          process.env.REACT_APP_BACK_URL +
            "/auth/getAllActiveUsers?companyId=" +
            companyId +
            "&limit=" +
            limit +
            "&skip=" +
            skip
        );

        //*ok
        setTimeout(() => {
          setusersDataFetched(newUsersReloadInit.data.users);
          window.location.reload();
        }, 1000);

        setIsOpenUpdate(false);
      }
    } catch (err) {
      console.log(err.response.data);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const openAdminModal = () => {
    setIsOpenAdmin(true);
  };

  const closeAdminModal = () => {
    setIsOpenAdmin(false);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModalUpdate = () => {
    setIsOpenUpdate(true);
  };

  const closeModalUpdate = () => {
    setIsOpenUpdate(false);
  };

  const openUploadModal = () => {
    setCsvData(undefined);
    setIsOpenUpload(true);
  };

  const closeUploadModal = () => {
    setIsOpenUpload(false);
  };

  const editUser = (item) => {
    setdataToUpdate(item);
    setIsOpenUpdate(true);
  };
  const handlePageClick = (pageNumber) => {
    setSelectedPage(pageNumber);
    setSkip(pageNumber - 1);
  };
  const handlePagePastClick = (pageNumber) => {
    setSelectedPage(pageNumber);
    setSkip(pageNumber - 1);
  };
  const handlePageNextClick = (pageNumber) => {
    setSelectedPage(pageNumber);
    setSkip(pageNumber - 1);
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const contents = reader.result;
      const chars = {
        Ž: "é",
        œ: "ú",
        "‡": "á",
        "’": "í",
        Ÿ: "ú",
      };
      const newContent = contents.replace(/[Žœ‡Ÿ’]/g, (x) => chars[x]);
      setCsvData(parseCsvData(newContent));
    };

    reader.readAsText(file, "ISO-8859-1");
  };

  const download = () => {
    const file = template;
    const link = document.createElement("a");
    link.href = file;
    link.download = "template-to-upload-users.csv";
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
  };

  const parseCsvData = (csvContent) => {
    const rows = csvContent.split("\n") || null;
    const headerIdentify = rows[0].split(";");
    let headers = "";
    let separator = "";
    if (headerIdentify[1]) {
      separator = ";";
    } else {
      separator = ",";
    }
    headers = rows[0].split(separator);
    const data = [];

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(separator);
      const rowData = {};

      for (let j = 0; j < headers.length; j++) {
        if (row[j] !== undefined && row[j] !== "") {
          rowData[headers[j]] = row[j];
        }
      }

      if (rowData?.identification) data.push(rowData);
    }

    const fileContainer = document.getElementById("fileUploadedMessage");
    if (data.length === 0) {
      setCsvData(undefined);
      fileContainer.innerText = "Error al cargar archivo";
      return;
    }

    fileContainer.innerHTML =
      "<label htmlFor='file' className='label-sm'>Archivo válido .csv separado por comas</label>";

    return data;
  };

  const fetchActiveUsers = async () => {
    const fetchData = async () => {
      const { data } = await axios.get(
        process.env.REACT_APP_BACK_URL +
          "/auth/getAllActiveUsers?companyId=" +
          companyId +
          "&limit=" +
          limit +
          "&skip=" +
          skip
      );

      localStorage.setItem("users-fetched", JSON.stringify(data.users));
      const companiesResponse = await axios.get(
        process.env.REACT_APP_BACK_URL + "/company/search/" + companyId
      );
      const { nombreCompany } = await axios.get(
        process.env.REACT_APP_BACK_URL + "/company/search/" + companyId
      );
      setcompaniesResponse(companiesResponse.data.name);

      const newUsersReloadInit = await axios.get(
        process.env.REACT_APP_BACK_URL +
          "/auth/getAllActiveUsers?companyId=" +
          companyId +
          "&limit=" +
          limit +
          "&skip=" +
          skip
      );

      setusersDataFetched(newUsersReloadInit.data.users);

      const arrayOfPages = [];

      for (
        let i = 1;
        i <= Math.ceil(newUsersReloadInit?.data?.count / limit);
        i++
      ) {
        arrayOfPages.push(i);
      }

      setPageNumbers(arrayOfPages);

      // if(newUsersReloadInit.data.users.length == 0) window.location.reload();
    };

    fetchData();


    if (usersDataFetched && usersDataFetched.length == 0) setNoInfo(true);
  };

  const fetchInactiveUsers = async () => {
    const fetchData = async () => {
      const { data } = await axios.get(
        process.env.REACT_APP_BACK_URL +
          "/auth/getUserBycompanyId?companyId=" +
          companyId +
          "&limit=" +
          limit +
          "&skip=" +
          skip
      );

      localStorage.setItem("users-fetched", JSON.stringify(data.users));
      const companiesResponse = await axios.get(
        process.env.REACT_APP_BACK_URL + "/company/search/" + companyId
      );
      const { nombreCompany } = await axios.get(
        process.env.REACT_APP_BACK_URL + "/company/search/" + companyId
      );
      setcompaniesResponse(companiesResponse.data.name);

      const newUsersReloadInit = await axios.get(
        process.env.REACT_APP_BACK_URL +
          "/auth/getUserBycompanyId?companyId=" +
          companyId +
          "&limit=" +
          limit +
          "&skip=" +
          skip
      );

      setusersDataFetched(newUsersReloadInit.data.users);

      const arrayOfPages = [];

      for (
        let i = 1;
        i <= Math.ceil(newUsersReloadInit?.data?.count / limit);
        i++
      ) {
        arrayOfPages.push(i);
      }

      setPageNumbers(arrayOfPages);
    };

    fetchData();

    if (usersDataFetched && usersDataFetched.length == 0) setNoInfo(true);
  };

  const showUsers = () => {
    let checkboxmaster = document.querySelectorAll(
      '#checkboxMaster input[type="checkbox"]'
    );

    if (checkboxmaster[0]?.checked) {
      fetchInactiveUsers();
      setShowInactives(true);
    } else {
      fetchActiveUsers();
      setShowInactives(false);
    }
  };

  const handleUpload = async () => {
    var sinRegistrar = [];
    const fileContainer = document.getElementById("fileUploadedMessage");

    //  await csvData.forEach(async (element) => {
    for (const element of csvData) {
      let fechanacimiento = "";

      for (const key in element) {
        if (key.trim() === "fechanacimiento") {
          fechanacimiento = element[key].replace(/"/g, "").trim();
          break;
        }
      }

      const dataToSend = {
        identification: element?.identification,
        name: element?.name,
        lastname: element?.lastname,
        email: element?.email,
        password: element?.password,
        fechanacimiento: new Date(fechanacimiento),
        phone: element?.phone,
        genero: element?.genero,
        cargo: element?.cargo,
        pais: element?.pais,
        ciudad: element?.ciudad,
        area: element?.area,
        isActive: true,
        companyId: companyId,
      };

      try {
        const response = await axios.post(
          process.env.REACT_APP_BACK_URL + "/auth/create",
          dataToSend
        );

        if (response.data?.existIn) {
          dataToSend.fechanacimiento = fechanacimiento;

          sinRegistrar.push(dataToSend);
          dataToSend.observacion = "Registro Existente";
          if (
            response.data?.msgE?.keyPattern?.identification === 1 &&
            response.data?.msgE?.keyValue?.identification
          ) {
            dataToSend.observacion =
              "problemas con el id:" +
              response.data?.msgE?.keyValue?.identification;
          }
        } else {
          dataToSend.observacion = "Error en la carga";
        }
      } catch (err) {
        dataToSend.observacion = "Error en la carga";
        sinRegistrar.push(dataToSend);
      }
    } //)
    if (sinRegistrar.length >= 1) {
      MySwal.fire({
        title: (
          <strong>
            Usuarios cargados exitosamente, sin embargo en el archivo adjunto se
            muestran los que presentan incovenientes
          </strong>
        ),
        icon: "success",
      });
      setIsOpenUpload(false);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      MySwal.fire({
        title: <strong>Usuarios cargados exitosamente!</strong>,
        icon: "success",
      });
      setIsOpenUpload(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    // Crear las columnas en la hoja de cálculo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Reporte");
    worksheet.columns = [
      { header: "identification", key: "sinRegistrar.identification" },
      { header: "name", key: "sinRegistrar.name" },
      { header: "lastname", key: "sinRegistrar.lastname" },
      { header: "email", key: "sinRegistrar.email" },
      { header: "password", key: "sinRegistrar.password" },
      { header: "genero", key: "sinRegistrar.genero" },
      { header: "cargo", key: "sinRegistrar.cargo" },
      { header: "pais", key: "sinRegistrar.pais" },
      { header: "ciudad", key: "sinRegistrar.ciudad" },
      { header: "area", key: "sinRegistrar.area" },
      { header: "phone", key: "sinRegistrar.phone" },
      { header: "fechanacimiento", key: "sinRegistrar.fechanacimiento" },
      { header: "observacion", key: "sinRegistrar.observacion" },

      // Agrega las demás columnas que desees mostrar
    ];

    // Agregar los datos a la hoja de cálculo
    sinRegistrar.forEach((item) => {
      const row = worksheet.addRow({
        "sinRegistrar.identification": item.identification,
        "sinRegistrar.name": item.name,
        "sinRegistrar.lastname": item.lastname,
        "sinRegistrar.email": item.email,
        "sinRegistrar.password": item.password,
        "sinRegistrar.genero": item.genero,
        "sinRegistrar.cargo": item.cargo,
        "sinRegistrar.pais": item.pais,
        "sinRegistrar.ciudad": item.ciudad,
        "sinRegistrar.area": item.area,
        "sinRegistrar.phone": item.phone,
        "sinRegistrar.fechanacimiento": item.fechanacimiento,
        "sinRegistrar.observacion": item.observacion,
        // Agrega los demás campos que desees mostrar
      });
      const observacionCell = worksheet.getCell(`M${row.number}`);

      // Aplicar estilo de fondo rojo
      observacionCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF0000" }, // Código de color rojo
      };
    });

    // Convertir el libro de Excel a un blob
    const buffer = await workbook.xlsx.writeBuffer();

    // Crear el blob
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Generar el enlace de descarga
    const url = URL.createObjectURL(blob);

    // Descargar el archivo
    const link = document.createElement("a");
    link.href = url;
    link.download = "No registrados.xlsx";
    if (sinRegistrar.length > 0) {
      link.click(); /* */
    }
  };

  return (
    <div className="background-colored">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-2 justify-content-center sidebar">
          <Sidebar />
        </div>
        <div className="col-12 col-sm-9 justify-content-center datatable-container ml">
          <div className="header-container">
            <div>
              <a className="arrow" onClick={goBack}>
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
              </a>
              <span id="customized" className="header-label">
                {companiesResponse} / Usuarios{" "}
              </span>
            </div>
            <div className="dropdown">
              <button
                className="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Nuevo
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a className="dropdown-item" onClick={openModal}>
                  Usuario
                </a>
                <a className="dropdown-item" onClick={openUploadModal}>
                  Carga desde archivo
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end my-2" id="checkboxMaster">
            <span>Mostrar Usuarios Inactivos</span>
            <label className="mx-2 align-self-center">
              <input type="checkbox" onChange={showUsers}></input>
            </label>
          </div>
          {usersDataFetched && usersDataFetched.length > 0 && (
            <div className="mt-4 scroll-container">
              <table className="table table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col" className="th">
                      Nombres y Apellidos
                    </th>
                    <th scope="col" className="th">
                      Email
                    </th>
                    <th scope="col" className="th">
                      Cargo
                    </th>
                    <th scope="col" className="th">
                      Área
                    </th>
                    <th scope="col" className="th">
                      Ciudad
                    </th>
                    <th scope="col" className="th">
                      País
                    </th>
                    <th scope="col" className="th">
                      Fecha de nacimiento
                    </th>
                    <th scope="col" className="th">
                      Género
                    </th>
                    <th scope="col" className="th">
                      Teléfono
                    </th>
                    <th scope="col" className="th">
                      Estado
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {usersDataFetched.map((item, key) => (
                    <tr key={key}>
                      <td scope="row" className="users">
                        <a onClick={() => editUser(item)}>
                          {item?.name} {item?.lastname}
                        </a>
                      </td>
                      <td>{item?.email}</td>
                      <td>{item?.cargo}</td>
                      <td>{item?.area}</td>
                      <td>{item?.ciudad}</td>
                      <td>{item?.pais}</td>
                      <td>
                        {moment(item?.fechanacimiento)
                          .utcOffset("+00:00")
                          .format("DD/MM/YYYY")}
                      </td>
                      <td>{item?.genero}</td>
                      <td>{item?.phone}</td>
                      <td>{item?.isActive ? "Activo" : "Inactivo"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {noInfo && usersDataFetched.length == 0 && (
            <div className="container mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="alert alert-light" role="alert">
                    No se han encontrado registros
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row mt-4">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                {
                  pageNumbers && pageNumbers.length > 0 && (
                    <li className="page-item">
                      <button
                        type="submit"
                        className={`page-link ${skip > 0 ? "" : "disabled"}`}
                        onClick={() => handlePageClick(selectedPage - 1)}
                      >
                        {"<<"}
                      </button>
                    </li>
                  ) /* onClick={() => setSkip(skip - 1)} */
                }
                {pageNumbers.map((pageNumber, index) => (
                  <li className="page-item" key={pageNumber}>
                    <button
                      type="submit"
                      className={`page-link numbers ${
                        pageNumber <= 15 ? "show" : "hidden"
                      } ${
                        selectedPage === 1 && index === 0 && loaded
                          ? "first-page"
                          : ""
                      } ${pageNumber === selectedPage ? "selected-page" : ""}`}
                      onClick={() => handlePageClick(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li> /*  onClick={() => setSkip(pageNumber - 1)} */
                ))}
                {
                  pageNumbers && pageNumbers.length > 0 && (
                    <li className="page-item">
                      <button
                        type="submit"
                        className={`page-link ${
                          skip < pageNumbers.length - 1 ? "" : "disabled"
                        }`}
                        onClick={() => handlePageClick(selectedPage + 1)}
                      >
                        {">>"}
                      </button>
                    </li>
                  ) /* onClick={() => setSkip(skip + 1)} */
                }
              </ul>
            </nav>
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Crear nueva empresa"
          >
            <div role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Nuevo
                  </h5>
                </div>
                <hr className="disapearMobil" />
                <div className="modal-body">
                  <div className="container-fluid">
                    <Formik
                      initialValues={{
                        name: form.name,
                        lastname: form.lastname,
                        email: "",
                        phone: form.phone,
                        isActive: true,
                        genero: form.genero,
                        cargo: form.cargo,
                        pais: form.pais,
                        ciudad: form.ciudad,
                        area: form.area,
                        identification: form.identification,
                        fechanacimiento: form.fechanacimiento,
                        password: "",
                        isActive: true,
                        companyId: companyId,
                      }}
                      validationSchema={Yup.object({
                        // ...
                        email: Yup.string()
                          .email("Formato de email inválido")
                          .required("Campo email es requerido"),
                        // ...
                      })}
                      validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                          errors.company = "Campo email es requerido";
                        }

                        if (!values.phone) {
                          errors.phone = "Campo phone es requerido";
                        }
                        return errors;
                      }}
                      onSubmit={handleSubmit}
                    >
                      <Form>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Nombres <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                              placeholder="Ingresa nombre"
                              maxLength={100}
                              required
                            />
                            <ErrorMessage name="name" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Apellidos <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="lastname"
                              id="lastname"
                              className="form-control"
                              placeholder="Ingresa apellidos"
                              maxLength={100}
                              required
                            />
                            <ErrorMessage
                              name="lastname"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Email <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="email"
                              id="email"
                              className="form-control"
                              placeholder="Ingresa email"
                              maxLength={60}
                              required
                            />
                            {/*   value={newEmail}
                              onChange={(e) => validationform(e.target)} */}
                            <ErrorMessage name="email" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Cargo <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="cargo"
                              id="cargo"
                              className="form-control"
                              placeholder="Ingresa cargo"
                              maxLength={80}
                              required
                            />
                            <ErrorMessage name="cargo" component="div" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Teléfono <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="phone"
                              id="phone"
                              className="form-control"
                              placeholder="Ingresa teléfono"
                              maxLength={11}
                              onKeyDown={(e) => {
                                const key = e.keyCode || e.charCode;
                                if (key === 8) {
                                  return;
                                }
                                if (!/\d/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              required
                            />
                            <ErrorMessage name="phone" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              País <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="pais"
                              id="pais"
                              className="form-control"
                              placeholder="Ingresa país "
                              maxLength={60}
                              required
                            />
                            <ErrorMessage name="pais" component="div" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="username">Género</label>
                            <Field
                              as="select"
                              name="genero"
                              className="form-control select"
                              required
                            >
                              <option value="masculino">Masculino</option>
                              <option value="femenino">Femenino</option>
                            </Field>
                            {/*    <label htmlFor="company">
                              Genero <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="genero"
                              id="genero"
                              className="form-control"
                              placeholder="Ingresa "
                            /> */}
                            <ErrorMessage name="genero" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Ciudad <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="ciudad"
                              id="ciudad"
                              className="form-control"
                              placeholder="Ingresa ciudad"
                              maxLength={80}
                              required
                            />
                            <ErrorMessage name="ciudad" component="div" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Fecha de Nacimiento <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="date"
                              name="fechanacimiento"
                              id="fechanacimiento"
                              className="form-control"
                              placeholder="Ingresa "
                              required
                              max={hoyDia}
                            />
                            <ErrorMessage
                              name="fechanacimiento"
                              component="div"
                            />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Área <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="area"
                              id="area"
                              className="form-control"
                              placeholder="Ingresa área"
                              maxLength={60}
                              required
                            />
                            {/*   value={newArea}
                              onChange={(e) => validationform(e.target)} */}
                            <ErrorMessage name="area" component="div" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="contact">Identificación </label>
                            <Field
                              type="text"
                              name="identification"
                              id="identification"
                              className="form-control"
                              placeholder="Ingresa identificación del contacto"
                              maxLength={15}
                            />
                            {/*   value={newIdentification}
                              onChange={(e) => validationform(e.target)} */}
                            <ErrorMessage name="contact" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="password">
                              Password <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="password"
                              name="password"
                              id="password"
                              className="form-control"
                              placeholder="Ingresa password"
                              maxLength={15}
                              required
                            />
                            {/*   value={newIdentification}
                              onChange={(e) => validationform(e.target)} */}
                            <ErrorMessage name="password" component="div" />
                          </div>
                        </div>
                        <hr className="disapearMobil" />
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary-custom"
                            onClick={closeModal}
                          >
                            Regresar
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary-custom"
                          >
                            Guardar
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* Edit modal */}
          <Modal
            isOpen={modalIsOpenUpdate}
            onRequestClose={closeModalUpdate}
            style={customStyles}
            contentLabel="Actualizar Usuario"
          >
            <div role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    {dataToUpdate?.name} {dataToUpdate?.lastname}
                  </h5>
                </div>
                <hr className="disapearMobil" />
                <div className="modal-body">
                  <div className="container-fluid">
                    <Formik
                      initialValues={{
                        name: dataToUpdate?.name,
                        _id: dataToUpdate?._id,
                        lastname: dataToUpdate?.lastname,
                        email: dataToUpdate?.email,
                        phone: dataToUpdate?.phone,
                        isActive: dataToUpdate?.isActive
                          ? "activo"
                          : "inactivo",
                        genero: dataToUpdate?.genero,
                        cargo: dataToUpdate?.cargo,
                        fechanacimiento: moment(dataToUpdate?.fechanacimiento)
                          .utcOffset("+00:00")
                          .format("YYYY-MM-DD"),
                        pais: dataToUpdate?.pais,
                        ciudad: dataToUpdate?.ciudad,
                        area: dataToUpdate?.area,
                        identification: dataToUpdate?.identification,
                        password: dataToUpdate?.password,
                      }}
                      validationSchema={Yup.object({
                        // ...
                        email: Yup.string()
                          .email("Formato de email inválido")
                          .required("Campo email es requerido"),
                        // ...
                      })}
                      onSubmit={handleUpdate}
                    >
                      <Form>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Nombres <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                              placeholder="Ingresa nombre"
                              maxLength={100}
                              required
                            />
                            <ErrorMessage name="name" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="lastname">
                              Apellidos <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="lastname"
                              id="lastname"
                              className="form-control"
                              placeholder="Ingresa apellidos"
                              maxLength={100}
                              required
                            />
                            <ErrorMessage name="lastname" component="div" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Email <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="email"
                              id="email"
                              className="form-control"
                              placeholder="Ingresa email"
                              maxLength={60}
                              required
                            />
                            <ErrorMessage name="email" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Cargo <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="cargo"
                              id="cargo"
                              className="form-control"
                              placeholder="Ingresa cargo"
                              maxLength={80}
                              required
                            />
                            <ErrorMessage name="cargo" component="div" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Teléfono <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="phone"
                              id="phone"
                              className="form-control"
                              placeholder="Ingresa teléfono"
                              maxLength={11}
                              onKeyDown={(e) => {
                                const key = e.keyCode || e.charCode;
                                if (key === 8) {
                                  return;
                                }
                                if (!/\d/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              required
                            />
                            <ErrorMessage name="phone" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              País <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="pais"
                              id="pais"
                              className="form-control"
                              placeholder="Ingresa país "
                              maxLength={60}
                              required
                            />
                            <ErrorMessage name="pais" component="div" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="username">Género</label>
                            <Field
                              as="select"
                              name="genero"
                              className="form-control select"
                            >
                              <option value="masculino">Masculino</option>
                              <option value="femenino">Femenino</option>
                            </Field>
                            <ErrorMessage name="genero" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Ciudad <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="ciudad"
                              id="ciudad"
                              className="form-control"
                              placeholder="Ingresa ciudad"
                              maxLength={80}
                              required
                            />
                            <ErrorMessage name="ciudad" component="div" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Fecha de Nacimiento <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="date"
                              name="fechanacimiento"
                              id="fechanacimiento"
                              className="form-control"
                              placeholder="Ingresa"
                              required
                              max={hoyDia}
                            />
                            <ErrorMessage
                              name="fechanacimiento"
                              component="div"
                            />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="company">
                              Área <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="area"
                              id="area"
                              className="form-control"
                              placeholder="Ingresa área"
                              maxLength={60}
                              required
                            />
                            <ErrorMessage name="area" component="div" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="contact">Identificación </label>
                            <Field
                              type="text"
                              name="identification"
                              id="identification"
                              className="form-control"
                              placeholder="Ingresa identificación del contacto"
                              maxLength={15}
                            />
                            <ErrorMessage name="contact" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="password">
                              Password <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="password"
                              name="password"
                              id="password"
                              className="form-control"
                              placeholder="Ingresa password"
                              maxLength={15}
                              required
                            />
                            <ErrorMessage name="password" component="div" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="isActive">
                              Estado <sup>*</sup>{" "}
                            </label>
                            <Field
                              as="select"
                              name="isActive"
                              className="form-control select"
                            >
                              <option value="activo">Activo</option>
                              <option value="inactivo">Inactivo</option>
                            </Field>
                          </div>
                        </div>
                        <div className="mb-3 form-group col-6">
                          <Field
                            type="hidden"
                            name="_id"
                            id="_id"
                            className="form-control"
                            value={dataToUpdate?._id}
                          />
                        </div>
                        <hr className="disapearMobil" />
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary-custom"
                            onClick={closeModalUpdate}
                          >
                            Regresar
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary-custom"
                          >
                            Guardar
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* Upload modal */}
          <Modal
            isOpen={modalIsOpenUpload}
            onRequestClose={closeUploadModal}
            style={customStyles}
            contentLabel="Cargar Usuarios"
          >
            <div role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Nuevo
                  </h5>
                  <button
                    type="button"
                    className="btn btn-secondary-dark"
                    onClick={download}
                  >
                    Descargar plantilla
                  </button>
                </div>
                <hr />
                <div className="modal-body">
                  <div className="container-fluid">
                    <Formik
                      initialValues={{ file: form.file }}
                      // validate={(values) => {
                      //   const errors = {};
                      //   if (!values.file) {
                      //     errors.file = 'Debe cargar un archivo';
                      //   }
                      //   return errors;
                      // }}
                      onSubmit={handleUpload}
                    >
                      <Form>
                        <div className="row">
                          <div className="mb-3 form-group col-12 ">
                            <Field
                              type="file"
                              name="file"
                              id="file"
                              className="form-control"
                              accept=".csv"
                              onChange={handleFileUpload}
                            />
                            <div id="fileUploadedMessage">
                              <label htmlFor="file" className="label-sm">
                                Archivo válido .csv separado por comas
                              </label>
                            </div>
                            <ErrorMessage name="file" component="div" />
                          </div>
                        </div>
                        {csvData && (
                          <div>
                            <div className="m-0 scroll-container">
                              <table className="table table-striped text-center">
                                <thead>
                                  <tr>
                                    <th scope="col" className="th table-th">
                                      Nombres y Apellidos
                                    </th>
                                    <th scope="col" className="th table-th">
                                      Email
                                    </th>
                                    <th scope="col" className="th table-th">
                                      Cargo
                                    </th>
                                    <th scope="col" className="th table-th">
                                      Área
                                    </th>
                                    <th scope="col" className="th table-th">
                                      Ciudad
                                    </th>
                                    <th scope="col" className="th table-th">
                                      País
                                    </th>
                                    <th scope="col" className="th table-th">
                                      Fecha de nacimiento
                                    </th>
                                    <th scope="col" className="th table-th">
                                      Género
                                    </th>
                                    <th scope="col" className="th table-th">
                                      Teléfono
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {csvData &&
                                    csvData.map((item, key) => (
                                      <tr key={key}>
                                        <td scope="row">
                                          {item?.name} {item?.lastname}
                                        </td>
                                        <td>{item?.email}</td>
                                        <td>{item?.cargo}</td>
                                        <td>{item?.area}</td>
                                        <td>{item?.ciudad}</td>
                                        <td>{item?.pais}</td>
                                        <td>{item?.fechanacimiento}</td>
                                        <td>{item?.genero}</td>
                                        <td>{item?.phone}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                            <hr />
                            {csvData && (
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary-custom"
                                  onClick={closeUploadModal}
                                >
                                  Regresar
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary-custom"
                                >
                                  Cargar
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* Admin modal */}
          <Modal
            isOpen={modalIsOpenAdmin}
            onRequestClose={closeAdminModal}
            style={customStyles}
            contentLabel="Crear Admin"
          >
            <div role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Nuevo
                  </h5>
                </div>
                <hr className="disapearMobil" />
                <div className="modal-body">
                  <div className="container-fluid">
                    <Formik
                      initialValues={{
                        username: adminForm.username,
                        password: adminForm.password,
                        roles: adminForm.roles,
                        isActive: true,
                        companyId: companyId,
                      }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.username) {
                          errors.username =
                            "Campo nombre de usuario es requerido";
                        }

                        if (!values.password) {
                          errors.password = "Campo contraseña es requerido";
                        }

                        return errors;
                      }}
                      onSubmit={handleSubmitAdmin}
                    >
                      <Form>
                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="username">
                              Nombre de Usuario <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="text"
                              name="username"
                              id="username"
                              className="form-control"
                              placeholder="Ingresa nombre de usuario"
                              maxLength={60}
                            />
                            <ErrorMessage name="username" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="roles">Rol</label>
                            <Field
                              as="select"
                              name="roles"
                              className="form-control select"
                            >
                              <option value="admin">Admin</option>
                              <option value="superadmin">Super-admin</option>
                            </Field>
                            <ErrorMessage name="roles" component="div" />
                          </div>
                        </div>

                        <div className="row">
                          <div className="mb-3 form-group col-6">
                            <label htmlFor="password">
                              Password <sup>*</sup>{" "}
                            </label>
                            <Field
                              type="password"
                              name="password"
                              id="password"
                              className="form-control"
                              placeholder="Ingresa password"
                              maxLength={15}
                            />
                            <ErrorMessage name="password" component="div" />
                          </div>
                          <div className="mb-3 form-group col-6">
                            <Field
                              type="hidden"
                              name="companyId"
                              id="companyId"
                              className="form-control"
                              value={dataToUpdate?._id}
                            />
                          </div>
                        </div>
                        <hr className="disapearMobil" />
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary-custom"
                            onClick={closeAdminModal}
                          >
                            Regresar
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary-custom"
                          >
                            Guardar
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}
export default Users;
